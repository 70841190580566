import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {
    ListPage,
    accessConstants,
    filterConstants,
    LoadQuestionsAction,
    ChallengeDropdown,
    LoadPracticesAction
} from "mindsets-js-sdk";

import Table from "./Table";

export default function () {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(LoadPracticesAction());
    }, []);

    return (
        <>
            <h1>Questions</h1>
            <ListPage
                action={options => {
                    options['selectors[0]'] = 'challenge_version';
                    options['selectors[1]'] = 'challenge_name';
                    options['selectors[2]'] = 'practice_skills';

                    return LoadQuestionsAction(options)
                }}
                filter_columns={[
                    {
                        key: 'title',
                        name: 'Title',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'challenge_version_id',
                        name: 'Challenge version',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <ChallengeDropdown onSelect={challenge_version => {
                                    if (challenge_version) {
                                        setFilterValue(challenge_version.id);
                                    } else {
                                        setFilterValue('');
                                    }

                                    return challenge_version ? challenge_version.name + ' v.' + challenge_version.version : '';
                                }} unversioned={true} id='questions-challenge-version-filter'/>
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'challenge_code_id',
                        name: 'Challenge',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <ChallengeDropdown onSelect={challenge_version => {
                                    if (challenge_version) {
                                        setFilterValue(challenge_version.challenge_code.id);
                                    } else {
                                        setFilterValue('');
                                    }

                                    return challenge_version ? challenge_version.name : '';
                                }} id='questions-challenge-code-filter'/>
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'code',
                        name: 'Code',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'number',
                        name: 'Number',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL]
                    }
                ]}
                sort_columns={[
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'title',
                        name: 'Title'
                    },
                    {
                        key: 'number',
                        name: 'Number'
                    },
                    {
                        key: 'code',
                        name: 'Code'
                    }
                ]}
                entity={'questions'}
                permissions={[accessConstants.PERMISSION_CAN_SEE_CHALLENGES]}
                default_order_by='id'
            >
                <Table/>
            </ListPage>
        </>
    );
}
