import React from "react";
import PropTypes from "prop-types";
import {statesConstants} from "mindsets-js-sdk";

const StateField = function (
    {
        entity,
        onChange,
        outer_class_name = '',
        inner_class_name = '',
        label_class_name = '',
        select_class_name = 'form-control',
        label = 'State',
        disabled = false
    }
) {
    if (!entity.country || !statesConstants.STATES.hasOwnProperty(entity.country) || !statesConstants.STATES[entity.country]) {
        return null;
    }

    return (
        <div className={outer_class_name}>
            <label htmlFor="state" className={label_class_name}>{label}</label>
            <div className={inner_class_name}>
                <select
                    className={select_class_name}
                    id="state"
                    value={entity.state ? entity.state : ''}
                    onChange={event => onChange(event.target.value)}
                    disabled={disabled}
                >
                    <option value='' disabled={true}>Select state</option>
                    {Object.keys(statesConstants.STATES[entity.country]).map(state_code => {
                        return (
                            <option
                                key={state_code}
                                value={state_code}
                            >{statesConstants.STATES[entity.country][state_code]}</option>
                        )
                    })}
                </select>
            </div>
        </div>
    );
}

StateField.propTypes = {
    entity: PropTypes.shape({
        country: PropTypes.string,
        state: PropTypes.string
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    outer_class_name: PropTypes.string,
    inner_class_name: PropTypes.string,
    label_class_name: PropTypes.string,
    select_class_name: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool
}

export default StateField;
