import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {countriesConstants, DistrictDropdown, SetSchoolAction} from "mindsets-js-sdk";

const DistrictField = function ({setUpdated}) {
    const dispatch = useDispatch();
    const school = useSelector(state => state.school);
    if (!school.country || -1 === countriesConstants.COUNTRIES_WITH_DISTRICTS.indexOf(school.country)) {
        return null;
    }

    return (
        <div className="row">
            <label htmlFor="autoload-field" className="col-sm-2 col-form-label">District Name</label>
            <div className="col-sm-10">
                <DistrictDropdown
                    onSelect={district => {
                        let school_new = JSON.parse(JSON.stringify(school));
                        if (district) {
                            school_new.district = district;
                            school_new.district_id = district.id;
                        } else {
                            delete school_new.district;
                            school_new.district_id = null;
                        }
                        dispatch(SetSchoolAction(school_new));
                        setUpdated(true);

                        return district ? district.name : '';
                    }}
                    placeholder='District Name'
                    input_class='form-control'
                    country={school.country}
                    default_value={school.district ? school.district.name : null}
                    id='school-create-district'
                />
            </div>
        </div>
    );
}

DistrictField.propTypes = {
    setUpdated: PropTypes.func.isRequired
}

export default DistrictField;
