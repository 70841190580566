import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, filterConstants, SchoolDropdown, SchoolDropdownItem, SetContractAction} from "mindsets-js-sdk";

import {
    LoadContractSchoolsAction,
    LoadedContractSchoolsAction
} from "../../../actions/contract_actions";

export default function () {
    const dispatch = useDispatch();
    const contract_state = useSelector(state => state.contract);
    const schools = useSelector(state => state.contract_schools);
    let contract = JSON.parse(JSON.stringify(contract_state));
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (contract.district_id) {
            let options = {};
            options['filters[district_id][0]'] = filterConstants.EXPRESSION_EQUAL + '=' + contract.district_id;
            options.order_by = 'name';
            options.limit = 0;
            dispatch(LoadContractSchoolsAction(options));
        } else {
            dispatch(LoadedContractSchoolsAction([]));
        }
    }, [contract.district_id]);
    useEffect(() => {
        if (schools.length) {
            dispatch(LoadedContractSchoolsAction([]));
        }
    }, [contract.country, contract.state]);
    useEffect(() => {
        if (contract.schools) {
            delete contract.schools;
            dispatch(SetContractAction(contract));
        }
    }, [contract.country, contract.state, contract.district_id]);

    const renderDistrictSchools = function () {
        if (contract.district_id) {
            return (
                <div className='d-flex flex-wrap mx-n1'>
                    {schools.map(school => {
                        return (
                            <div key={school.id} className='bordered m-1'>
                                <Checkbox
                                    checked={!!contract.schools && contract.schools.some(school_x => school_x.id === school.id)}
                                    toggle={() => {
                                        if (contract.schools && contract.schools.some(school_x => school_x.id === school.id)) {
                                            const index = contract.schools.findIndex(school_x => school_x.id === school.id);
                                            contract.schools.splice(index, 1);
                                            if (contract.schools.length === 0) {
                                                delete contract.schools;
                                            }
                                        } else if (contract.schools) {
                                            contract.schools.push(school);
                                        } else {
                                            contract.schools = [school]
                                        }
                                        dispatch(SetContractAction(contract));
                                    }}
                                    label={<SchoolDropdownItem item={school}/>}
                                />
                            </div>
                        );
                    })}
                </div>
            );
        }
    }
    const renderNonDistrictSchools = function () {
        if (!contract.district_id) {
            return (
                <>
                    <SchoolDropdown onSelect={school => {
                        if (school) {
                            if (contract.schools && !contract.schools.some(school_x => school_x.id === school.id)) {
                                contract.schools.push(school);
                            } else {
                                contract.schools = [school]
                            }
                        }
                        dispatch(SetContractAction(contract));

                        return '';
                    }} country={contract.country} state={contract.state} label='Find a school'
                                    id='gradeing-consistency-school'/>
                    <div className='d-flex flex-wrap mx-n1'>
                        {contract.schools ? contract.schools.map((school, index) => {
                            return (
                                <div key={school.id} className='bordered m-1 d-flex'>
                                    <SchoolDropdownItem item={school}/>
                                    <i
                                        className='fas fa-times-circle fa-2x clickable'
                                        onClick={() => {
                                            contract.schools.splice(index, 1);
                                            if (contract.schools.length === 0) {
                                                delete contract.schools;
                                            }
                                            dispatch(SetContractAction(contract));
                                        }}
                                    />
                                </div>
                            );
                        }) : null}
                    </div>
                </>
            );
        }
    }

    if (open) {
        return (
            <div>
                <button onClick={event => {
                    event.preventDefault();
                    setOpen(false);
                }}>Hide
                </button>
                {renderDistrictSchools()}
                {renderNonDistrictSchools()}
            </div>
        );
    } else {
        return (
            <div>
                <button onClick={event => {
                    event.preventDefault();
                    setOpen(true);
                }}>show
                </button>
            </div>
        );
    }
}
