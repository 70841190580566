import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {Checkbox} from "mindsets-js-sdk";

import {
    SetChallengeBuilderChallengePagesAction,
    SetChallengeBuilderMouseOverComponentAction,
    SetChallengeBuilderMouseOverRowAction,
    SetChallengeBuilderSelectedComponentIndexAction,
    SetChallengeBuilderSelectedRowIndexAction
} from "../../../actions/challenge_actions";

export default function () {
    const dispatch = useDispatch();

    const index = useSelector(state => state.challenge_builder_index);
    const challenge_pages = useSelector(state => state.challenge_builder_challenge_pages);
    const mouse_over_row = useSelector(state => state.challenge_builder_mouse_over_row);
    const mouse_over_component = useSelector(state => state.challenge_builder_mouse_over_component);
    const selected_row_index = useSelector(state => state.challenge_builder_selected_row_index);
    const selected_component_index = useSelector(state => state.challenge_builder_selected_component_index);

    let pages = JSON.parse(JSON.stringify(challenge_pages));
    let page = pages[index];
    let counter = -1;
    let items = [];
    if (page && page.data && page.data.card_content && Array.isArray(page.data.card_content.rows)) {
        page.data.card_content.rows.map((row, row_index) => {
            counter++;
            items.push(
                {
                    source_index: counter,
                    type: 'row',
                    row_index,
                    row,
                    item: (
                        <Draggable
                            draggableId={'row_structure_drag_' + row_index.toString()}
                            key={'row_structure_drag_' + row_index}
                            index={counter}
                            type='row'
                        >
                            {providedDrag => (
                                <div
                                    ref={providedDrag.innerRef}
                                    {...providedDrag.draggableProps}
                                    {...providedDrag.dragHandleProps}
                                >
                                    <div
                                        onClick={event => {
                                            event.stopPropagation();
                                            dispatch(SetChallengeBuilderSelectedRowIndexAction(row_index === selected_row_index && selected_component_index === null ? null : row_index));
                                            dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
                                        }}
                                        onMouseOver={() => dispatch(SetChallengeBuilderMouseOverRowAction(row_index))}
                                        onMouseLeave={() => dispatch(SetChallengeBuilderMouseOverRowAction(null))}
                                        className={'clickable ' + (row_index === selected_row_index && null === selected_component_index || mouse_over_row === row_index ? 'selected' : '')}
                                    >Row
                                        #{row_index + 1} {renderDuplicateButton(row_index)} {renderDeleteButton(row_index)}</div>
                                    {providedDrag.placeholder}
                                </div>
                            )}
                        </Draggable>
                    )
                }
            );
            if (row && Array.isArray(row.components)) {
                row.components.map((component, component_index) => {
                    counter++;
                    items.push(
                        {
                            source_index: counter,
                            type: 'component',
                            row_index,
                            component_index,
                            component,
                            item: (
                                <Draggable
                                    draggableId={'component_structure_drag_' + row_index + '_' + component_index.toString()}
                                    key={'component_structure_drag_' + row_index + '_' + component_index}
                                    index={counter}
                                >
                                    {providedDrag => (
                                        <div
                                            ref={providedDrag.innerRef}
                                            {...providedDrag.draggableProps}
                                            {...providedDrag.dragHandleProps}
                                        >
                                            <div
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    dispatch(SetChallengeBuilderSelectedRowIndexAction(component_index === selected_component_index && row_index === selected_row_index ? null : row_index));
                                                    dispatch(SetChallengeBuilderSelectedComponentIndexAction(component_index === selected_component_index && selected_row_index === row_index ? null : component_index));
                                                }}
                                                onMouseOver={() => dispatch(SetChallengeBuilderMouseOverComponentAction({
                                                    row_index,
                                                    component_index
                                                }))}
                                                onMouseLeave={() => dispatch(SetChallengeBuilderMouseOverComponentAction(null))}
                                                className={'clickable ' + ((selected_component_index === component_index && selected_row_index === row_index) || (!!mouse_over_component && component_index === mouse_over_component.component_index && row_index === mouse_over_component.row_index) ? 'selected' : '')}
                                            >
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{_.upperFirst(component.type)} {renderDuplicateButton(row_index, component_index)} {renderDeleteButton(row_index, component_index)}
                                            </div>
                                            {providedDrag.placeholder}
                                        </div>
                                    )}
                                </Draggable>
                            )
                        }
                    );
                });
            }
        });
    }

    function renderDeleteButton(row_index, component_index) {
        if (row_index === selected_row_index && null === selected_component_index && (null === component_index || undefined === component_index)) {
            return (
                <i
                    className='clickable fas fa-trash text-danger'
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();

                        pages[index].data.card_content.rows.splice(row_index, 1);
                        dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
                        dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
                        dispatch(SetChallengeBuilderChallengePagesAction(pages));
                    }}
                />
            );
        } else if (row_index === selected_row_index && component_index === selected_component_index && undefined !== component_index && null !== component_index) {
            return (
                <i
                    className='clickable fas fa-trash text-danger'
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();

                        pages[index].data.card_content.rows[row_index].components.splice(component_index, 1);
                        dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
                        dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
                        dispatch(SetChallengeBuilderChallengePagesAction(pages));
                    }}
                />
            );
        }

        return null;
    }

    function renderDuplicateButton(row_index, component_index) {
        if (row_index === selected_row_index && null === selected_component_index && (null === component_index || undefined === component_index)) {
            return (
                <i
                    className='clickable fas fa-clone text-info'
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();

                        pages[index].data.card_content.rows.splice(row_index, 0, _.cloneDeep(pages[index].data.card_content.rows[row_index]));
                        dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
                        dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
                        dispatch(SetChallengeBuilderChallengePagesAction(pages));
                    }}
                />
            );
        } else if (row_index === selected_row_index && component_index === selected_component_index && undefined !== component_index && null !== component_index) {
            return (
                <i
                    className='clickable fas fa-clone text-info'
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();

                        pages[index].data.card_content.rows[row_index].components.splice(component_index, 0, _.cloneDeep(pages[index].data.card_content.rows[row_index].components[component_index]));
                        dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
                        dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
                        dispatch(SetChallengeBuilderChallengePagesAction(pages));
                    }}
                />
            );
        }

        return null;
    }

    function onDragEnd(result) {
        if (!result || !result.draggableId) {
            return;
        }

        const {source, destination} = result;
        if (!destination || !destination.droppableId) {
            return;
        }

        if (destination.droppableId === 'droppable_structure_rows_and_components') {
            const source_item = items[source.index];
            const destination_item = items[destination.index];

            if (source_item.type === 'component' && destination_item.type === 'row') {
                //Move above this row and put at the end
                //1. Remove from old place
                const [removed] = page.data.card_content.rows[source_item.row_index].components.splice(source_item.component_index, 1);
                //1. Copy to new place
                if (source_item.row_index >= destination_item.row_index) {
                    page.data.card_content.rows[destination_item.row_index - 1].components.push(removed);
                } else {
                    page.data.card_content.rows[destination_item.row_index].components.unshift(removed);
                }
            } else if (source_item.type === 'component' && destination_item.type === 'component') {
                //1. Remove from old place
                const [removed] = page.data.card_content.rows[source_item.row_index].components.splice(source_item.component_index, 1);
                page.data.card_content.rows[destination_item.row_index].components.splice(
                    source_item.row_index >= destination_item.row_index ? destination_item.component_index : destination_item.component_index + 1,
                    0,
                    removed
                );
            } else if (source_item.type === 'row' && destination_item.type === 'row') {
                const [removed] = page.data.card_content.rows.splice(source_item.row_index, 1);
                page.data.card_content.rows.splice(
                    destination_item.row_index,
                    0,
                    removed
                );
            } else {
                const [removed] = page.data.card_content.rows.splice(source_item.row_index, 1);
                page.data.card_content.rows.splice(
                    destination_item.row_index,
                    0,
                    removed
                );
            }
            pages[index] = page;
            dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
            dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
            dispatch(SetChallengeBuilderChallengePagesAction(pages));
        }

        return null;
    }

    function renderAddRow() {
        if (['intro', 'unlock', 'final'].includes(page.type)) {
            return null;
        }

        return (
            <div>
                <button
                    className='button blue'
                    onClick={() => {
                        let pages = JSON.parse(JSON.stringify(challenge_pages));
                        let page = pages[index];
                        let page_data = page.data;
                        if (!page_data.hasOwnProperty('card_content')) {
                            page_data.card_content = {};
                        }
                        let card_content = page_data.card_content;
                        if (!card_content.hasOwnProperty('rows')) {
                            card_content.rows = [];
                        }
                        let rows = card_content.rows;
                        rows.push({components: []});
                        card_content.rows = rows;
                        page_data.card_content = card_content;
                        page.data = page_data;
                        pages[index] = page;
                        dispatch(SetChallengeBuilderChallengePagesAction(pages));
                    }}
                >+ ROW
                </button>
            </div>
        );
    }

    function renderAnswerRequiredCheck() {
        if (['intro', 'unlock', 'final'].includes(page.type)) {
            return null;
        }

        return (
            <Checkbox
                checked={!!page && !!page.data && !!page.data.answer_required}
                label='Answer required?'
                toggle={() => {
                    let page_new = JSON.parse(JSON.stringify(page));
                    if (!page_new.data) {
                        page_new.data = {};
                    }
                    page_new.data.answer_required = !page_new.data.answer_required;

                    let challenge_pages_new = JSON.parse(JSON.stringify(challenge_pages));
                    challenge_pages_new[index] = page_new;
                    dispatch(SetChallengeBuilderChallengePagesAction(challenge_pages_new));
                }}
            />
        );
    }

    function renderIsBonusCheck() {
        if (['intro', 'unlock', 'final'].includes(page.type)) {
            return null;
        }

        return (
            <Checkbox
                checked={!!page && !!page.data && !!page.data.is_bonus}
                label='Is bonus?'
                toggle={() => {
                    let page_new = JSON.parse(JSON.stringify(page));
                    if (!page_new.data) {
                        page_new.data = {};
                    }
                    page_new.data.is_bonus = !page_new.data.is_bonus;

                    let challenge_pages_new = JSON.parse(JSON.stringify(challenge_pages));
                    challenge_pages_new[index] = page_new;
                    dispatch(SetChallengeBuilderChallengePagesAction(challenge_pages_new));
                }}
            />
        );
    }

    function renderStaticMessage() {
        if (['intro', 'unlock', 'final'].includes(page.type)) {
            return (
                <div>
                    <h5 className='text-warning'>This page type is static and does not support customization</h5>
                </div>
            );
        }

        return null;
    }

    return (
        <div className='bordered'>
            <div className='structure-bar'>
                <h4>Page #{index + 1}</h4>
                {renderAnswerRequiredCheck()}
                {renderIsBonusCheck()}
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId="droppable_structure_rows_and_components"
                        type='rows_and_components'
                        direction="vertical"
                    >
                        {provided => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{overflowY: 'scroll'}}
                            >
                                {items.map(item => item.item)}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {renderAddRow()}
                {renderStaticMessage()}
            </div>
        </div>
    );
}
