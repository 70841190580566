import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    SchoolDropdown,
    ChallengeDropdown,
    UserDropdown,
    DatePicker,
    DistrictDropdown,
    ListPage,
    accessConstants,
    filterConstants,
    LoadLessonsAction,
    catchQueryFilters
} from "mindsets-js-sdk";

import {LoadCurriculaAction, LoadPracticesAction} from "../../actions/lesson_actions";
import Table from "./components/Table";
import BulkActions from "./components/BulkActions";

export default function () {
    const curricula = useSelector(state => state.lessons_curricula);
    const practices = useSelector(state => state.lessons_practices);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!curricula.length) {
            dispatch(LoadCurriculaAction());
        }
        if (!practices.length) {
            dispatch(LoadPracticesAction());
        }
    }, []);
    if (!curricula.length || !practices.length) {
        return null;
    }

    return (
        <>
            <h1>Classes</h1>
            <ListPage
                action={options => LoadLessonsAction(Object.assign({
                    'selectors[0]': 'students_count',
                    'selectors[1]': 'co_teachers_count',
                    'selectors[2]': 'user_school',
                    'selectors[3]': 'challengeVersion_challengeCode',
                    'selectors[4]': 'lesson_scoring_schedules',
                    'selectors[5]': 'last_activity_date',
                }, options))}
                filter_columns={[
                    {
                        key: 'id',
                        name: 'ID',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'code',
                        name: 'Code',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'name',
                        name: 'Name',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'mode',
                        name: 'Mode',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL],
                        values: [
                            {
                                label: 'Student-paced',
                                value: 'student'
                            },
                            {
                                label: 'Teacher-driven',
                                value: 'teacher'
                            }
                        ]
                    },
                    {
                        key: 'challenge_version_id',
                        name: 'Challenge version',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <ChallengeDropdown onSelect={challenge_version => {
                                    if (challenge_version) {
                                        setFilterValue(challenge_version.id);
                                    } else {
                                        setFilterValue('');
                                    }

                                    return challenge_version ? challenge_version.name : '';
                                }} unversioned={true} id='lessons-challenge-version-filter'/>
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'challenge_code',
                        name: 'Challenge',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <ChallengeDropdown onSelect={challenge_version => {
                                    if (challenge_version) {
                                        setFilterValue(challenge_version.challenge_code.code);
                                    } else {
                                        setFilterValue('');
                                    }

                                    return challenge_version ? challenge_version.name : '';
                                }} id='lessons-challenge-code-filter'/>
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'students_count',
                        name: 'No. students',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE]
                    },
                    {
                        key: 'co_teachers_count',
                        name: 'No. Co-teachers',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE]
                    },
                    {
                        key: 'created_at',
                        name: 'Created At',
                        expressions: [filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE],
                        component: (setFilterValue, index) => {
                            const filters = catchQueryFilters();
                            const filter = filters[index];
                            return (
                                <DatePicker
                                    date={filter && filter.value ? filter.value : null}
                                    onChange={setFilterValue}/>
                            );
                        },
                    },
                    {
                        key: 'is_open',
                        name: 'Is Open',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'is_answer_keys_shown',
                        name: 'Is answer keys shown',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'is_done',
                        name: 'Is Completed',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'user_id',
                        name: 'Creator/Owner',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <UserDropdown onSelect={user => {
                                    if (user) {
                                        setFilterValue(user.id);
                                    } else {
                                        setFilterValue('');
                                    }

                                    return user ? user.fname + ' ' + user.lname : '';
                                }}/>
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'student_id',
                        name: 'Student ID',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <UserDropdown onSelect={user => {
                                    if (user) {
                                        setFilterValue(user.id);
                                    } else {
                                        setFilterValue('');
                                    }

                                    return user ? user.fname + ' ' + user.lname : '';
                                }} persona='student'/>
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'co_teacher_id',
                        name: 'Co-Teacher ID',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <UserDropdown onSelect={user => {
                                    if (user) {
                                        setFilterValue(user.id);
                                    } else {
                                        setFilterValue('');
                                    }

                                    return user ? user.fname + ' ' + user.lname : '';
                                }}/>
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'school_id',
                        name: 'School',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <SchoolDropdown onSelect={school => {
                                    if (school) {
                                        setFilterValue(school.id);
                                    } else {
                                        setFilterValue('');
                                    }

                                    return school ? school.name : '';
                                }} id='lessons-school-filter'/>
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'schedule_status',
                        name: 'Assessment Status',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        values: [
                            {
                                label: 'Requested',
                                value: 'requested'
                            },
                            {
                                label: 'Started',
                                value: 'started'
                            },
                            {
                                label: 'Finished',
                                value: 'finished'
                            }
                        ]
                    },
                    {
                        key: 'is_scheduled',
                        name: 'Scheduled',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'last_activity_date',
                        name: 'Last activity',
                        expressions: [filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LTE, filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_LT],
                        component: (setFilterValue, index) => {
                            const filters = catchQueryFilters();
                            const filter = filters[index];
                            return (
                                <DatePicker
                                    date={filter && filter.value ? filter.value : null}
                                    onChange={timestamp => {
                                        setFilterValue(new Date(timestamp * 1000).toISOString());
                                    }}/>
                            );
                        },
                    },
                    {
                        key: 'provider',
                        name: 'Students from',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        values: [{
                            label: 'Google Classroom',
                            value: 'google'
                        }, {
                            label: 'Microsoft SDS',
                            value: 'office'
                        }, {
                            label: 'Clever',
                            value: 'clever'
                        }, {
                            label: 'ClassLink',
                            value: 'classlink'
                        }],
                        multiple: false
                    },
                    {
                        key: 'district_id',
                        name: 'By District',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <DistrictDropdown onSelect={district => {
                                    if (district) {
                                        setFilterValue(district.id);
                                    } else {
                                        setFilterValue('');
                                    }

                                    return district ? district.name : '';
                                }} id='lessons-district-filter'/>
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'grade_level',
                        name: 'Grade Level',
                        expressions: [filterConstants.EXPRESSION_CONTAINS],
                        values: [
                            {
                                label: 'Grade 3',
                                value: 3
                            },
                            {
                                label: 'Grade 4',
                                value: 4
                            },
                            {
                                label: 'Grade 5',
                                value: 5
                            },
                            {
                                label: 'Grade 6',
                                value: 6
                            },
                            {
                                label: 'Grade 7',
                                value: 7
                            },
                            {
                                label: 'Grade 8',
                                value: 8
                            },
                            {
                                label: 'Grade 9',
                                value: 9
                            },
                            {
                                label: 'Grade 10',
                                value: 10
                            },
                            {
                                label: 'Grade 11',
                                value: 11
                            },
                            {
                                label: 'Grade 12',
                                value: 12
                            }
                        ]
                    }
                ]}
                sort_columns={[
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'code',
                        name: 'Code'
                    },
                    {
                        key: 'name',
                        name: 'Name'
                    },
                    {
                        key: 'students_count',
                        name: 'No. of students'
                    },
                    {
                        key: 'created_at',
                        name: 'Created At'
                    },
                    {
                        key: 'last_activity_date',
                        name: 'Last activity'
                    }
                ]}
                entity={'lessons'}
                permissions={[accessConstants.PERMISSION_CAN_SEE_ALL_LESSONS]}
                default_order_direction='desc'
                create_button={<BulkActions/>}
            >
                <Table/>
            </ListPage>
        </>
    );
}
