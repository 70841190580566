import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    accessConstants,
    filterConstants,
    authUtils,
    LoadQuestionsAction,
    LoadedQuestionsAction,
    LoadGradingProvidersAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import {LoadPracticesAction} from "../../actions/response_actions";
import Filters from "./components/Filters";
import Question from "./components/Question";
import {LoadGradingConsistencyAction} from "../../actions/statistics";
import ResponseContent from "../../common/ResponseContent";

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const data = useSelector(state => state.grading_consistency);
    const questions = useSelector(state => state.questions);
    const [challenge_code, setChallengeCode] = useState({code: null});
    const [question_code, setQuestionCode] = useState('');
    const [practice_id, setPracticeId] = useState('');
    const [lesson_code, setLessonCode] = useState('');
    const [grading_provider_id, setGradingProviderId] = useState('');
    const [from, setFrom] = useState(null);
    const [till, setTill] = useState(null);
    const action = function () {
        if (!!challenge_code) {
            let options = {};
            if (question_code) {
                options.question_code = question_code
            } else if (challenge_code) {
                options.challenge_code = challenge_code.code
            }
            if (lesson_code) {
                options.lesson_codes = lesson_code
            }
            if (practice_id) {
                options.practice_id = practice_id
            }
            if (grading_provider_id) {
                options.grading_provider_id = grading_provider_id
            }
            if (from) {
                const dateFrom = new Date(from * 1000);
                dateFrom.setUTCHours(0, 0, 0, 0);
                options.from = dateFrom.toISOString().substring(0, 10);
            }
            if (till) {
                const dateTill = new Date(till * 1000);
                dateTill.setUTCHours(0, 0, 0, 0);
                options.till = dateTill.toISOString().substring(0, 10);
            }
            dispatch(LoadGradingConsistencyAction(options));
        }
    }

    useEffect(() => {
        if (challenge_code.code) {
            setQuestionCode('');
            dispatch(LoadedQuestionsAction([]));
            dispatch(LoadQuestionsAction({
                order_by: 'number',
                order_direction: 'asc',
                'filters[challenge_code][0]': filterConstants.EXPRESSION_EQUAL + '=' + challenge_code.code,
                'selectors[0]': 'practice_skills',
                'selectors[1]': 'poll_poll_options',
                'selectors[2]': 'by_code'
            }));
        }
    }, [challenge_code.code]);
    useEffect(() => {
        dispatch(LoadPracticesAction());
        dispatch(LoadGradingProvidersAction());
    }, []);

    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_REQUEST_STATISTICS])) {
        return (
            <NoAccess/>
        );
    }

    function getOverallConsistencyRate() {
        let overall_sum = 0;
        let overall_count = 0;
        Object.keys(data).map(question_code => {
            const question_consistency = getQuestionConsistencyRate(data[question_code]);
            overall_count++;
            overall_sum = overall_sum + question_consistency;
        });

        return (Math.round((overall_sum / overall_count + Number.EPSILON) * 100) / 100).toString();
    }

    function getQuestionConsistencyRate(question_data) {
        let question_count = 0;
        let question_sum = 0;
        Object.keys(question_data).map(variant => {
            const variant_data = question_data[variant];

            let variant_total = 0;
            let biggest = 0;
            Object.keys(variant_data).map(points => {
                const times = variant_data[points];
                variant_total = variant_total + times;
                if (times > biggest) {
                    biggest = times;
                }
            });
            const variant_consistency = biggest * 100 / variant_total;
            question_count++;
            question_sum = question_sum + variant_consistency;
        });

        return Math.round((question_sum / question_count + Number.EPSILON) * 100) / 100;
    }

    return (
        <>
            <h1>Grading Consistency Review</h1>
            <Filters
                action={action}
                question_code={question_code}
                setQuestionCode={setQuestionCode}
                practice_id={practice_id}
                setPracticeId={setPracticeId}
                challenge_code={challenge_code}
                setChallengeCode={setChallengeCode}
                lesson_code={lesson_code}
                setLessonCode={setLessonCode}
                grading_provider_id={grading_provider_id}
                setGradingProviderId={setGradingProviderId}
                from={from}
                till={till}
                setFrom={setFrom}
                setTill={setTill}

            />
            <h2>Overall consistency {getOverallConsistencyRate()}%</h2>
            <div className='section'>
                {Object.keys(data).map(question_code => {
                    const question_data = data[question_code];
                    const question = questions.find(question => question.code === question_code);
                    if (!question) {
                        return null;
                    }

                    return (
                        <div key={question_code}>
                            <Question
                                question_code={question_code}
                                practice_id={practice_id ? parseInt(practice_id) : null}/>
                            <h4>Consistency rate per question: {getQuestionConsistencyRate(question_data)}%</h4>
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Response variant</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.keys(question_data).map(variant => {
                                    const variant_data = question_data[variant];

                                    return (
                                        <tr key={variant}>
                                            <td>
                                                <ResponseContent question={question} response={JSON.parse(variant)}/>
                                            </td>
                                            <td colSpan={2}>
                                                <table className="table table-borderedr">
                                                    <thead>
                                                    <tr>
                                                        <th>Points given</th>
                                                        <th>Times</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {Object.keys(variant_data).map(points => {
                                                        return (
                                                            <tr key={variant + '-' + points}>
                                                                <td>{points}</td>
                                                                <td>{variant_data[points]}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
