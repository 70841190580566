import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {
    SchoolDropdown,
    ListPage,
    ReloadListAction,
    filterConstants,
    accessConstants,
    catchQueryFilters
} from "mindsets-js-sdk";

import Table from './components/Table';
import {LoadLongitudinalStatsAction} from "../../actions/longitudinal_actions";

export default function () {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const me = useSelector(state => state.me);
    const filters = catchQueryFilters();
    useEffect(() => {
        const filters_filtered = filters.filter(filter => filter.column === 'student_school_id');
        if (filters_filtered.length <= 0 && me.school) {
            searchParams.set('column~0', 'student_school_id');
            searchParams.set('expression~0', filterConstants.EXPRESSION_EQUAL);
            searchParams.set('value~0', me.school.id);
            setSearchParams(searchParams);
            dispatch(ReloadListAction(true, 'longitudinal_stats'));
        }
    }, []);

    const filters_filtered = filters.filter(filter => filter.column === 'student_school_id');

    return (
        <>
            <h1>Students</h1>
            <ListPage
                action={LoadLongitudinalStatsAction}
                filter_columns={[
                    {
                        key: 'id',
                        name: 'ID',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'email',
                        name: 'E-mail',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'fname',
                        name: 'First Name',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'lname',
                        name: 'Last Name',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'streams_count',
                        name: 'No. of Classes Joined',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE]
                    },
                    {
                        key: 'student_school_id',
                        name: 'School',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <SchoolDropdown
                                    schoolName={me && me.school ? me.school.name : null}
                                    onSelect={school => {
                                        if (school) {
                                            setFilterValue(school.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return school ? school.name : '';
                                    }}
                                    id='longitudinal-school-filter'
                                />
                            );
                        },
                        multiple: false
                    }
                ]}
                sort_columns={[
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'email',
                        name: 'E-mail'
                    },
                    {
                        key: 'fname',
                        name: 'First Name'
                    },
                    {
                        key: 'lname',
                        name: 'Last Name'
                    },
                    {
                        key: 'streams_count',
                        name: 'No. of Classes Joined'
                    }
                ]}
                entity={'longitudinal_stats'}
                permissions={[accessConstants.PERMISSION_CAN_SEE_USERS, accessConstants.PERMISSION_CAN_REQUEST_STATISTICS]}
                tips={[
                    'When filtering, keep in mind that:',
                    '1. Students are not connected to schools directly, but through classes->teachers->schools.',
                    '2. A student will not be "connected" to a school if a teacher who created a class the student is joined is not assigned to a school.',
                    '3. A student might be a member of more than one school if one joined a few classes created by teachers from different schools',
                    '4. Not all of the users presented are currently students. Some of them might have changed their persona to teacher. But they are still shown here because they joined at least one class.'
                ]}
            >
                <Table
                    school_id={filters_filtered.length > 0 && filters[0].value ? parseInt(filters_filtered[0].value) : null}/>
            </ListPage>
        </>
    );
}
