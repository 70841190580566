import React from 'react';

import BaseComponentProperty from "../index";

export default class ChoiceSingle extends BaseComponentProperty {
    hasQuestionNumber() {
        return true;
    }

    hasConfigs() {
        return true;
    }

    renderConfigs() {
        return (
            <div>configs</div>
        );
    }

    renderOwnProperties() {
        return (
            <div>
                <button className='button mb-2' onClick={() => {
                    let data = this.props.component.data;
                    let options = data.options;
                    const keys = Object.keys(options);
                    let next_key = 'a';
                    if (keys.length > 0) {
                        const last_key = keys[keys.length - 1];
                        next_key = String.fromCharCode(last_key.charCodeAt(0) + 1);
                    }
                    options[next_key] = '';
                    data.options = options;
                    this.props.onChange('data', data);
                }}>Add option
                </button>
                <h4>Options</h4>
                <div className='d-flex flex-row flex-wrap flex-box-0-5 flex-gap-5'>
                    {Object.keys(this.props.component.data.options).map((option_key, index) => {
                        return (
                            <div key={index} style={{position: 'relative'}} className='bordered'>
                                <i
                                    className='fas fa-trash text-danger clickable'
                                    style={{position: 'absolute', right: '5px', top: '5px'}}
                                    onClick={() => {
                                        let data = this.props.component.data;
                                        delete data.options[option_key];
                                        let options = data.options;
                                        Object.keys(options).map((option_key, option_index) => {
                                            if (option_key !== String.fromCharCode(option_index + 97)) {
                                                options[String.fromCharCode(option_index + 97)] = options[option_key];
                                                delete options[option_key];
                                            }
                                        });
                                        this.props.onChange('data', data);
                                    }}
                                />
                                {option_key}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
