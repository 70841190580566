import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

function NumericValueField({initial, callback}) {
    const [value, setValue] = useState(initial !== null && initial !== undefined ? initial.toString() : '');

    useEffect(() => {
        setValue(initial !== null && initial !== undefined ? initial.toString() : '');

        return () => setValue('');
    }, [initial]);

    function renderError() {
        if (value.length > 0 && (isNaN(parseFloat(value)) || parseFloat(value).toString() !== value.toString())) {
            return (
                <div className='text-danger text-sm-left'>Invalid value. Must be a valid numeric(integer or decimal)
                    value</div>
            );
        }

        return null;
    }

    return (
        <>
            <input
                value={value}
                className='form-control'
                onChange={event => {
                    const val = event.target.value;
                    setValue(val);
                    if (val === '') {
                        callback(null);
                    } else if (!isNaN(parseFloat(val))) {
                        callback(parseFloat(val));
                    }
                }}/>
            {renderError()}
        </>
    );
}

NumericValueField.propTypes = {
    initial: PropTypes.number,
    callback: PropTypes.func.isRequired
}

export default NumericValueField;
