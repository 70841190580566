import React from 'react';

import BaseComponentProperty from "../index";

export default class DataTabs extends BaseComponentProperty {
    renderOwnProperties() {
        return (
            <div>
                <button className='button mb-2' onClick={() => {
                    let data = this.props.component.data;
                    let items = data.items;
                    items.push({
                        title: "",
                        image_url: "",
                        image_alt: "",
                        html_content_1: "",
                        html_content_2: ""
                    });
                    data.items = items;
                    this.props.onChange('data', data);
                }}>+
                </button>
                <div className='d-flex flex-row flex-wrap'>
                    {this.props.component.data.items.map((item, index) => {
                        return (
                            <div key={index} style={{position: 'relative'}} className='bordered'>
                                <i
                                    className='fas fa-trash text-danger clickable'
                                    style={{position: 'absolute', right: '-15px', top: '-10px'}}
                                    onClick={() => {
                                        let data = this.props.component.data;
                                        data.items.splice(index, 1);
                                        this.props.onChange('data', data);
                                    }}
                                />
                                <div className="row">
                                    <label className="col-sm-1 col-form-label">Title</label>
                                    <div className="col-sm-11">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={item.title ? item.title : ''}
                                            onChange={event => {
                                                let data = this.props.component.data;
                                                let items = data.items;
                                                item.title = event.target.value;
                                                items[index] = item;
                                                data.items = items;
                                                this.props.onChange('data', data);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-1 col-form-label">Image url</label>
                                    <div className="col-sm-11">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={item.image_url ? item.image_url : ''}
                                            onChange={event => {
                                                let data = this.props.component.data;
                                                let items = data.items;
                                                item.image_url = event.target.value;
                                                items[index] = item;
                                                data.items = items;
                                                this.props.onChange('data', data);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-1 col-form-label">Image alt</label>
                                    <div className="col-sm-11">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={item.image_alt ? item.image_alt : ''}
                                            onChange={event => {
                                                let data = this.props.component.data;
                                                let items = data.items;
                                                item.image_alt = event.target.value;
                                                items[index] = item;
                                                data.items = items;
                                                this.props.onChange('data', data);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-1 col-form-label">HTML content 1</label>
                                    <div className="col-sm-11">
                                        <textarea
                                            className="form-control"
                                            value={item.html_content_1 ? item.html_content_1 : ''}
                                            onChange={event => {
                                                let data = this.props.component.data;
                                                let items = data.items;
                                                item.html_content_1 = event.target.value;
                                                items[index] = item;
                                                data.items = items;
                                                this.props.onChange('data', data);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-1 col-form-label">HTML content 2</label>
                                    <div className="col-sm-11">
                                        <textarea
                                            className="form-control"
                                            value={item.html_content_2 ? item.html_content_2 : ''}
                                            onChange={event => {
                                                let data = this.props.component.data;
                                                let items = data.items;
                                                item.html_content_2 = event.target.value;
                                                items[index] = item;
                                                data.items = items;
                                                this.props.onChange('data', data);
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
