import React from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, countriesConstants, SetUserAction, SchoolDropdownExtended} from "mindsets-js-sdk";

const Schools = function ({updated, setUpdated}) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const roles = useSelector(state => state.user_roles);

    if (user.roles.some(user_role => {
        const role = roles.find(role => role.id === user_role.id);
        if (role) {
            return Array.isArray(role.permissions) ? role.permissions.map(permission => permission.permission_id).includes(accessConstants.PERMISSION_CAN_HAVE_MANY_SCHOOLS) : false;
        } else {
            return false;
        }
    })) {
        return (
            <div>
                <h4>ASSIGNED SCHOOLS</h4>
                <hr/>
                <div>
                    <SchoolDropdownExtended
                        onSelect={school => {
                            let user_new = JSON.parse(JSON.stringify(user));
                            if (!user_new.schools) {
                                user_new.schools = [];
                            }
                            if (school) {
                                if (!user_new.schools.some(user_school => user_school.id === school.id)) {
                                    user_new.schools.push(school);
                                }
                            }
                            dispatch(SetUserAction(user_new));
                            if (!updated) {
                                setUpdated(true);
                            }

                            return school ? school.name : '';
                        }}
                        placeholder='School Name'
                        input_class='form-control'
                        with_district={true}
                        country={user.country}
                        state={user.state}
                        id='user-school-dropdown-extended'
                        label='School'
                        label_class='mb-0'
                        district_id={user.school && user.school.district_id ? user.school.district_id : (user.school && user.school.district ? user.school.district.id : null)}
                        onReset={() => {
                            return '';
                        }}
                    />
                </div>
                {Array.isArray(user.schools) ? user.schools.map(school => {
                    let location = [];
                    if (school.location) {
                        location.push(school.location);
                    }
                    if (school.city) {
                        location.push(school.city);
                    }
                    if (school.state) {
                        location.push(school.state);
                    }
                    if (school.district && school.district.name) {
                        location.push(school.district.name);
                    }
                    if (school.country && countriesConstants.COUNTRIES.hasOwnProperty(school.country)) {
                        location.push(school.country);
                    }
                    if (school.zip) {
                        location.push(school.zip);
                    }

                    return (
                        <div key={school.id} className='card mt-2'>
                            <div className="card-body d-flex justify-content-between align-items-center p-2 flex-gap-5">
                                <div>
                                    <div className="school-title"><strong>{school.name}</strong></div>
                                    <div className="school-location">{location.join(', ')}</div>
                                </div>
                                <a href={'!#'} onClick={event => {
                                    event.preventDefault();
                                    let user_new = JSON.parse(JSON.stringify(user));
                                    const school_index = user_new.schools.findIndex(school_x => school_x.id === school.id);
                                    if (school_index >= 0) {
                                        user_new.schools.splice(school_index, 1);
                                    }
                                    dispatch(SetUserAction(user_new));
                                    setUpdated(true);
                                }}>
                                    <i className='fas fa-trash fa-lg'/>
                                </a>
                            </div>
                        </div>
                    );
                }) : null}
            </div>
        );
    }

    return null;
};

Schools.propTypes = {
    updated: PropTypes.bool.isRequired,
    setUpdated: PropTypes.func.isRequired
};

export default Schools;
