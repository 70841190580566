import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    accessConstants,
    authUtils,
    UserDropdown,
    LoadGradingProviderAction,
    SetGradingProviderAction,
    UpdateGradingProviderAction,
    LoadingAction,
    ErrorMessageAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import Users from "./Users";

export default function () {
    const me = useSelector(state => state.me);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const grading_provider = useSelector(state => state.grading_provider);
    useEffect(() => {
        dispatch(LoadGradingProviderAction(params.grading_provider_id));

        return () => dispatch(SetGradingProviderAction({}));
    }, []);
    if (!me || !grading_provider) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_USERS])) {
        return (
            <NoAccess/>
        );
    }
    const [updated, setUpdated] = useState(false);

    return (
        <div className='container section'>
            <h1>{grading_provider.name ? grading_provider.name : ''}</h1>
            <form onSubmit={event => {
                event.preventDefault();
                dispatch(UpdateGradingProviderAction(params.grading_provider_id, {
                    name: grading_provider.name,
                    user_ids: grading_provider.users.map(user => user.id)
                })).then(() => navigate(-1)).catch(error => {
                    dispatch(LoadingAction(false));
                    dispatch(ErrorMessageAction(error));
                    console.error(error);
                });
            }} autoComplete="off" id="update-grading_provider-form">
                <div className="row">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Name"
                            value={grading_provider.name ? grading_provider.name : ''}
                            onChange={event => {
                                let grading_provider_new = JSON.parse(JSON.stringify(grading_provider));
                                grading_provider_new.name = event.target.value;
                                dispatch(SetGradingProviderAction(grading_provider_new));
                                setUpdated(true);
                            }}/>
                    </div>
                </div>

                <div>
                    <h4>Assigned users</h4>
                    <div>
                        <UserDropdown onSelect={new_user => {
                            let users = [];
                            if (Array.isArray(grading_provider.users)) {
                                users = JSON.parse(JSON.stringify(grading_provider.users));
                            }
                            if (new_user && users.findIndex(user => user.id === new_user.id) < 0) {
                                users.push({
                                    id: new_user.id,
                                    email: new_user.email,
                                    fname: new_user.fname,
                                    lname: new_user.lname
                                });
                            }

                            let grading_provider_new = JSON.parse(JSON.stringify(grading_provider));
                            grading_provider_new.users = users;
                            dispatch(SetGradingProviderAction(grading_provider_new));
                            setUpdated(true);

                            return new_user ? new_user.fname + ' ' + new_user.lname : '';
                        }} placeholder='Search user'/>
                    </div>
                </div>
                <Users setUpdated={setUpdated}/>

                <div className='d-flex flex-gap-5'>
                    <button type="submit" className="button"
                            disabled={!updated || !grading_provider.name || grading_provider.name.length < 3}>Submit
                    </button>
                    <button className="button default" onClick={event => {
                        event.preventDefault();
                        setUpdated(false);
                        navigate(-1);
                    }}>Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}
