import React from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {DeleteScheduleAction, UpdateScheduleAction, ReloadListAction, datesConstants} from "mindsets-js-sdk";

import {MINIMUM_COMPLETION_LEVELS} from "./index";


const Row = function ({item, row_number}) {
    const dispatch = useDispatch();
    const practices = useSelector(state => state.lessons_practices);
    const grading_providers = useSelector(state => state.schedules_grading_providers);
    const getGradingProvider = function () {
        const grading_provider = grading_providers.find(grading_provider => grading_provider.id === item.grading_provider_id);
        if (grading_provider) {
            return grading_provider.name;
        }

        return 'NOT SET';
    }

    return (
        <tr>
            <td>{row_number}</td>
            <td>
                <div><strong>{item.lesson.code.toUpperCase()}</strong></div>
                <div
                    className='no-wrap'>{item.lesson.challenge_version.name}(v.{item.lesson.challenge_version.version})
                </div>
                <div
                    className='no-wrap'>{item.lesson.mode === 'teacher' ? 'Teacher-paced mode' : 'Student paced mode'}</div>
            </td>
            <td>{_.upperFirst(item.status)}</td>
            <td className='no-wrap'>{new Date(item.lesson.created_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</td>
            <td className='no-wrap'>{new Date(item.created_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</td>
            <td>{getGradingProvider()}</td>
            <td>{practices.find(practice => practice.id === item.practice_id).name}</td>
            <td>{MINIMUM_COMPLETION_LEVELS[item.completion]}</td>
            <td className='text-center'>
                <span
                    className={item.number_students_graded !== item.number_students_to_grade ? 'text-danger' : ''}
                >{item.number_students_graded >= 0 ? item.number_students_graded : 'na'}</span>
                <span>/</span>
                <span>{item.number_students_to_grade >= 0 ? item.number_students_to_grade : 'na'}</span>
                <span>/</span>
                <span>{item.number_students >= 0 ? item.number_students : 'na'}</span>
            </td>
            <td className='text-center'>
                <span
                    className={item.number_responses_graded !== item.number_responses_to_grade ? 'text-danger' : ''}
                >{item.number_responses_graded >= 0 ? item.number_responses_graded : 'na'}</span>
                <span>/</span>
                <span
                    className='text-info'>{item.number_responses_to_grade >= 0 ? item.number_responses_to_grade : 'na'}</span>
                <span>/</span>
                <span
                    className='text-warning'>{item.number_responses_auto_graded >= 0 ? item.number_responses_auto_graded : 'na'}</span>
                <span>/</span>
                <span>{item.number_responses_gradable >= 0 ? item.number_responses_gradable : 'na'}</span>
                <span>/</span>
                <span className='text-success'>{item.number_responses >= 0 ? item.number_responses : 'na'}</span>
            </td>
            <td>
                {item.status !== 'finished' ? (
                    <button className='button red mx-2 my-1' onClick={event => {
                        event.preventDefault();
                        if (confirm('WARNING!!! This action will permanently remove ALL scores/grades. Are you sure?')) {
                            dispatch(DeleteScheduleAction(item.id)).then(() => dispatch(ReloadListAction(true, 'schedules')));
                        }
                    }}>
                        <i className='fas fa-trash'/>
                    </button>
                ) : null}
                {item.status === 'finished' ? (
                    <button className='button mx-2 my-1' onClick={event => {
                        event.preventDefault();
                        dispatch(UpdateScheduleAction(item.id, {status: 'started'})).then(() => dispatch(ReloadListAction(true, 'schedules')));
                    }}>
                        <i className='fas fa-unlock'/>
                    </button>
                ) : null}
            </td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string.isRequired,
        user_id: PropTypes.number,
        lesson_id: PropTypes.number,
        practice_id: PropTypes.number.isRequired,
        grading_provider_id: PropTypes.number,
        completion: PropTypes.string,
        created_at: PropTypes.number,
        lesson: PropTypes.shape({
            id: PropTypes.number.isRequired,
            code: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            challenge_version: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
        number_students: PropTypes.number,
        number_students_to_grade: PropTypes.number,
        number_students_graded: PropTypes.number,
        number_responses: PropTypes.number,
        number_responses_to_grade: PropTypes.number,
        number_responses_graded: PropTypes.number,
        number_responses_gradable: PropTypes.number,
        number_responses_auto_graded: PropTypes.number
    }).isRequired,
    row_number: PropTypes.number.isRequired
};

export default Row;
