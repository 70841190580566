import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    accessConstants,
    authUtils,
    datesConstants,
    rolesConstants,
    ErrorMessageAction,
    SuccessMessageAction,
    SchoolDropdown,
    countriesConstants,
    Checkbox,
    LoadUserAction,
    SetUserAction,
    UpdateUserAction,
    LoadingAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import Schools from "./components/Schools";
import {
    LoadUserCurriculaAction,
    LoadUserRolesAction
} from "../../actions/user_actions";
import States from "./components/States";
import SubmitButtons from "./components/SubmitButtons";

export default function () {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const user = useSelector(state => state.user);
    const curricula = useSelector(state => state.user_curricula);
    const roles = useSelector(state => state.user_roles);
    const validate = function () {
        let errors = 0;
        if (!user.fname || user.fname === '') {
            dispatch(ErrorMessageAction('Error! Please specify the user\'s first name'));
            errors++;
        }
        if (!user.lname || user.lname === '') {
            dispatch(ErrorMessageAction('Error! Please specify the user\'s last name'));
            errors++;
        }
        if (rolesConstants.ROLE_TEACHER === user.persona && (!user.country || user.country === '')) {
            dispatch(ErrorMessageAction('Error! Please specify the user\'s country'));
            errors++;
        }

        return errors === 0;
    }
    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        if (me && authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_USERS])) {
            dispatch(LoadUserCurriculaAction());
            dispatch(LoadUserRolesAction());
            dispatch(LoadUserAction(params.user_id, {
                'selectors[0]': 'roles',
                'selectors[1]': 'school_subscriptions',
                'selectors[2]': 'district_subscriptions',
                'selectors[3]': 'schools'
            }));
        } else {
            dispatch(ErrorMessageAction('You do not have enough permissions to access this area', true));
        }

        return () => {
            setUpdated(false);
            dispatch(SetUserAction(null));
        }
    }, []);
    if (!me || !user) {
        return null;
    }

    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_USERS])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <form onSubmit={event => {
            event.preventDefault();
            if (validate()) {
                let data = {
                    fname: user.fname,
                    lname: user.lname,
                    persona: user.persona,
                    country: user.country,
                    state: user.state,
                    curriculum_id: user.curriculum_id,
                    school_id: user.school_id,
                    special: user.special,
                    notes: user.notes,
                    status: user.status,
                    teacher_subscribed: user.teacher_subscribed,
                    password_reset: user.password_reset,
                    roles: user.roles.map(role => role.id)
                };
                if (user.schools && user.roles.some(user_role => {
                    const role = roles.find(role => role.id === user_role.id);
                    if (role) {
                        return role.permissions.map(permission => permission.permission_id).includes(accessConstants.PERMISSION_CAN_HAVE_MANY_SCHOOLS)
                    } else {
                        return false;
                    }
                })) {
                    data.school_ids = user.schools.map(school => school.id);
                }
                dispatch(UpdateUserAction(user.id, data)).then(() => {
                    dispatch(SuccessMessageAction('The user has been updated.'));
                    navigate(-1);
                }).catch(error => {
                    dispatch(LoadingAction(false));
                    dispatch(ErrorMessageAction(error));
                    console.error(error);
                });
            }
        }} autoComplete="off" id="users-form">
            <h1>{user.fname + ' ' + user.lname}</h1>

            <div className="row">
                <div className="col-6">
                    <h4>USER</h4>
                    <hr/>
                    <div className="row">
                        <label htmlFor="fname" className="col-sm-3 col-form-label">First Name</label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                className="form-control"
                                id="fname"
                                placeholder="First Name"
                                value={user.fname}
                                onChange={event => {
                                    let user_new = JSON.parse(JSON.stringify(user));
                                    user_new.fname = event.target.value;
                                    dispatch(SetUserAction(user_new));
                                    if (!updated) {
                                        setUpdated(true);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label htmlFor="lname" className="col-sm-3 col-form-label">Last Name</label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                className="form-control"
                                id="lname"
                                placeholder="Last Name"
                                value={user.lname}
                                onChange={event => {
                                    let user_new = JSON.parse(JSON.stringify(user));
                                    user_new.lname = event.target.value;
                                    dispatch(SetUserAction(user_new));
                                    if (!updated) {
                                        setUpdated(true);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label htmlFor="email" className="col-sm-3 col-form-label">Account Email ID</label>
                        <div className="col-sm-9">
                            <input
                                disabled={true}
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Account Email ID"
                                defaultValue={user.email}/>
                        </div>
                    </div>
                    <div className="row">
                        <label htmlFor="persona" className="col-sm-3 col-form-label">Persona</label>
                        <div className="col-sm-9">
                            <select
                                className="form-control"
                                id="persona"
                                value={user.persona}
                                onChange={event => {
                                    let user_new = JSON.parse(JSON.stringify(user));
                                    user_new.persona = event.target.value;
                                    dispatch(SetUserAction(user_new));
                                    if (!updated) {
                                        setUpdated(true);
                                    }
                                }}
                            >
                                <option value='' disabled>SELECT PERSONA</option>
                                <option value='teacher'>Teacher</option>
                                <option value='student'>Student</option>
                            </select>
                        </div>
                    </div>
                    <div className='d-flex flex-wrap mb-3'>
                        {roles.map(role => {
                            const user_role_index = user.roles.findIndex(userRole => userRole.id === role.id);
                            const userRole = user.roles[user_role_index];

                            return (
                                <Checkbox
                                    key={role.id}
                                    label={role.name}
                                    checked={!!userRole}
                                    disabled={userRole && userRole.initial}
                                    toggle={() => {
                                        if (!userRole || !userRole.initial) {
                                            let user_new = JSON.parse(JSON.stringify(user));
                                            if (user_role_index >= 0) {
                                                user_new.roles.splice(user_role_index, 1);
                                            } else {
                                                user_new.roles.push({
                                                    id: role.id,
                                                    initial: false
                                                });
                                            }
                                            dispatch(SetUserAction(user_new));
                                            if (!updated) {
                                                setUpdated(true);
                                            }
                                        }
                                    }}
                                />
                            );
                        })}
                    </div>
                    <div className="row">
                        <label htmlFor="country" className="col-sm-3 col-form-label">Country</label>
                        <div className="col-sm-9">
                            <select
                                className="form-control"
                                id="country"
                                value={user.country ? user.country : ''}
                                onChange={event => {
                                    let user_new = JSON.parse(JSON.stringify(user));
                                    user_new.country = event.target.value;
                                    dispatch(SetUserAction(user_new));
                                    if (!updated) {
                                        setUpdated(true);
                                    }
                                }}
                            >
                                <option value='' disabled>SELECT COUNTRY</option>
                                {Object.keys(countriesConstants.COUNTRIES).map(code => {
                                    return (
                                        <option
                                            key={code}
                                            value={code}>{countriesConstants.COUNTRIES[code]}</option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <States user={user} updated={updated} setUpdated={setUpdated}/>
                    <div className="row">
                        <label htmlFor="country" className="col-sm-3 col-form-label">Curriculum</label>
                        <div className="col-sm-9">
                            <select
                                className="form-control"
                                id="curriculum"
                                value={user.curriculum_id ? user.curriculum_id : ''}
                                onChange={event => {
                                    let user_new = JSON.parse(JSON.stringify(user));
                                    user_new.curriculum_id = parseInt(event.target.value);
                                    dispatch(SetUserAction(user_new));
                                    if (!updated) {
                                        setUpdated(true);
                                    }
                                }}
                            >
                                <option value='' disabled>SELECT CURRICULUM</option>
                                {curricula.map(curriculum => <option key={curriculum.id}
                                                                     value={curriculum.id}>{curriculum.name}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <label htmlFor="autoload-field" className="col-sm-3 col-form-label">School Name</label>
                        <div className="col-sm-9">
                            <SchoolDropdown
                                onSelect={school => {
                                    let user_new = JSON.parse(JSON.stringify(user));
                                    if (school) {
                                        user_new.school = school;
                                        user_new.school_id = school.id;
                                    } else {
                                        delete user_new.school;
                                        user_new.school_id = null;
                                    }
                                    dispatch(SetUserAction(user_new));
                                    if (!updated) {
                                        setUpdated(true);
                                    }

                                    return school ? school.name : '';
                                }}
                                placeholder='School Name'
                                input_class='form-control'
                                default_value={user.school ? user.school.name : ''}
                                with_district={true}
                                country={user.country}
                                id='user-update-school'
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label htmlFor="district-name" className="col-sm-3 col-form-label">District Name</label>
                        <div className="col-sm-9">
                            <input
                                disabled={true}
                                type="text"
                                className="form-control"
                                id="district-name"
                                value={user.school && user.school.district ? user.school.district.name : ''}
                                onChange={() => {
                                }}/>
                        </div>
                    </div>
                    <div className="row">
                        <label htmlFor="created_at" className="col-sm-3 col-form-label">Account Creation
                            Date</label>
                        <div className="col-sm-9">
                            <input
                                disabled={true}
                                type="text"
                                className="form-control"
                                id="created_at"
                                defaultValue={new Date(user.created_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}/>
                        </div>
                    </div>
                    <div className="row">
                        <label htmlFor="status" className="col-sm-3 col-form-label">Status</label>
                        <div className="col-sm-9">
                            <select
                                className="form-control"
                                id="status"
                                value={user.status}
                                onChange={event => {
                                    let user_new = JSON.parse(JSON.stringify(user));
                                    user_new.status = event.target.value;
                                    dispatch(SetUserAction(user_new));
                                    if (!updated) {
                                        setUpdated(true);
                                    }
                                }}
                            >
                                <option value='active'>Active</option>
                                <option value='inactive'>Inactive</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-9">
                            <Checkbox
                                label='Subscribed'
                                checked={user.teacher_subscribed === 1}
                                toggle={() => {
                                    let user_new = JSON.parse(JSON.stringify(user));
                                    user_new.teacher_subscribed = user.teacher_subscribed === 1 ? 0 : 1;
                                    dispatch(SetUserAction(user_new));
                                    if (!updated) {
                                        setUpdated(true);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-9">
                            <Checkbox
                                label='Password reset requested'
                                checked={user.password_reset === 1}
                                toggle={() => {
                                    let user_new = JSON.parse(JSON.stringify(user));
                                    user_new.password_reset = user.password_reset === 1 ? 0 : 1;
                                    dispatch(SetUserAction(user_new));
                                    if (!updated) {
                                        setUpdated(true);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <hr/>
                </div>
                <div className="col-6">
                    <Schools updated={updated} setUpdated={setUpdated}/>
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <div>
                        <label htmlFor="special">Special Case</label>
                        <input
                            type="text"
                            className="form-control"
                            id="special"
                            placeholder="Innovator/Parent?"
                            value={user.special ? user.special : ''}
                            onChange={event => {
                                let user_new = JSON.parse(JSON.stringify(user));
                                user_new.special = event.target.value;
                                dispatch(SetUserAction(user_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}
                        />
                    </div>
                    <div>
                        <label htmlFor="notes">Notes/Comments</label>
                        <textarea
                            rows={3}
                            className="form-control"
                            id="notes"
                            value={user.notes ? user.notes : ''}
                            onChange={event => {
                                let user_new = JSON.parse(JSON.stringify(user));
                                user_new.notes = event.target.value;
                                dispatch(SetUserAction(user_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            <SubmitButtons updated={updated}/>
        </form>
    );
}
