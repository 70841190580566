import React from "react";
import PropTypes from "prop-types";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
    contentUtils,
    HTMLContent,
    ErrorMessageAction,
    LoadingAction,
    MultipleSelector
} from "mindsets-js-sdk";

import CorrectAnswer from "./CorrectAnswer";
import SkillSelector from "./SkillSelector";
import Points from "./Points";
import {getDummyQuestionPracticeSkill, isNumeric} from "./utils";
import {
    CreateQuestionPracticeSkillAction,
    DeleteQuestionPracticeSkillAction,
    SetQuestionPracticeSkillsUpdatedAction,
    UpdateQuestionPracticeSkillAction
} from "../../actions/question_actions";

function UpdateRubricForm({question, loadQuestionPracticeSkills, updated}) {
    const params = useParams();
    const dispatch = useDispatch();
    const questions = useSelector(state => state.questions);
    const standard_topics = useSelector(state => state.standard_topics);
    const question_practice_skills = useSelector(state => state.question_practice_skills);
    const selected_practice_id = useSelector(state => state.selected_practice_id);
    const question_practice_skills_updated = useSelector(state => state.question_practice_skills_updated);
    const question_practice_skill_updated = question_practice_skills_updated.find(skill => skill.question_code === question.code && skill.practice_id === selected_practice_id);
    const topics = standard_topics.filter(topic => topic.practice_id === selected_practice_id).map(topic => topic.topic);

    function renderDeleteButton() {
        if (question_practice_skill_updated && question_practice_skill_updated.id > 0) {
            return (
                <button
                    className="button red"
                    onClick={event => {
                        event.preventDefault();
                        const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                        dispatch(DeleteQuestionPracticeSkillAction(question_practice_skill_updated.id)).then(() => {
                            question_practice_skills_updated_x[question_practice_skills_updated_x.findIndex(r => r.question_code === question.code && r.practice_id === selected_practice_id)] = JSON.parse(JSON.stringify(getDummyQuestionPracticeSkill(question.code, selected_practice_id)));
                            dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                        }).then(loadQuestionPracticeSkills);
                    }}
                >Delete data for this question
                </button>
            );
        }

        return null;
    }

    function renderResetButton() {
        if (updated) {
            return (
                <button
                    className="button"
                    onClick={event => {
                        event.preventDefault();
                        const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));

                        if (question_practice_skill_updated && question_practice_skill_updated.id > 0) {
                            question_practice_skills_updated_x[question_practice_skills_updated_x.findIndex(r => r.question_code === question.code && r.practice_id === selected_practice_id)] = JSON.parse(JSON.stringify(question_practice_skills[question_practice_skills.findIndex(s => s.question_code === question.code && s.practice_id === selected_practice_id)]));
                        } else {
                            question_practice_skills_updated_x[question_practice_skills_updated_x.findIndex(r => r.question_code === question.code && r.practice_id === selected_practice_id)] = JSON.parse(JSON.stringify(getDummyQuestionPracticeSkill(question.code, selected_practice_id)));
                        }
                        dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                    }}
                >Remove all changes
                </button>
            );
        }

        return null;
    }

    function renderSaveButton() {
        if (updated) {
            return (
                <button
                    className="button"
                    onClick={event => {
                        event.preventDefault();
                        if (validate()) {
                            if (question_practice_skill_updated.id) {
                                // TODO A state mutation was detected between dispatches, in the path 'question_practice_skills_updated.28.questions.0'.
                                dispatch(UpdateQuestionPracticeSkillAction(question_practice_skill_updated.id, {
                                    practice_skill_id: question_practice_skill_updated.practice_skill_id,
                                    dok: question_practice_skill_updated.dok ? question_practice_skill_updated.dok : null,
                                    skill: question_practice_skill_updated.skill,
                                    example_solution: question_practice_skill_updated.example_solution,
                                    correct_answer: question_practice_skill_updated.correct_answer,
                                    points: question_practice_skill_updated.points,
                                    notes: question_practice_skill_updated.notes,
                                    domain: question_practice_skill_updated.domain,
                                    question_codes: question_practice_skill_updated.questions ? question_practice_skill_updated.questions : null
                                })).then(loadQuestionPracticeSkills).catch(error => {
                                    dispatch(LoadingAction(false));
                                    dispatch(ErrorMessageAction(error));
                                    console.error(error);
                                });
                            } else {
                                dispatch(CreateQuestionPracticeSkillAction({
                                    question_code: question.code,
                                    challenge_code: params.challenge_code,
                                    practice_skill_id: question_practice_skill_updated.practice_skill_id,
                                    dok: question_practice_skill_updated.dok ? question_practice_skill_updated.dok : null,
                                    skill: question_practice_skill_updated.skill,
                                    example_solution: question_practice_skill_updated.example_solution,
                                    correct_answer: question_practice_skill_updated.correct_answer,
                                    points: question_practice_skill_updated.points,
                                    notes: question_practice_skill_updated.notes,
                                    domain: question_practice_skill_updated.domain,
                                    question_codes: question_practice_skill_updated.questions ? question_practice_skill_updated.questions : null
                                })).then(loadQuestionPracticeSkills).catch(error => {
                                    dispatch(LoadingAction(false));
                                    dispatch(ErrorMessageAction(error));
                                    console.error(error);
                                });
                            }
                        }
                    }}
                >Submit
                </button>
            );
        }

        return null;
    }

    function validate() {
        let errors = 0;

        if (!question_practice_skill_updated.practice_skill_id) {
            dispatch(ErrorMessageAction('Error! Please select math practice category for question #' + question.number + '.'));
            errors++;
        }
        if (!question_practice_skill_updated.skill || question_practice_skill_updated.skill === '') {
            dispatch(ErrorMessageAction('Error! Please define Skill for question #' + question.number + '.'));
            errors++;
        }
        if (!question_practice_skill_updated.example_solution || question_practice_skill_updated.example_solution === '') {
            dispatch(ErrorMessageAction('Error! Please define Example solution for question #' + question.number + '.'));
            errors++;
        }
        if (!question_practice_skill_updated.points || question_practice_skill_updated.points.length <= 0) {
            dispatch(ErrorMessageAction('Error! Please define points for question #' + question.number + '.'));
            errors++;
        } else if (question_practice_skill_updated.points && question_practice_skill_updated.points.length <= 1) {
            dispatch(ErrorMessageAction('Error! Please define at leas 2 points(0 and 1) for question #' + question.number + '.'));
            errors++;
        } else if (question_practice_skill_updated.points && question_practice_skill_updated.points.length > 1) {
            question_practice_skill_updated.points.map(point => {
                if (!point.description || point.description === '') {
                    dispatch(ErrorMessageAction('Error! Please define Description for point ' + point.point + ' for question #' + question.number + '.'));
                    errors++;
                }
            });
        }
        if (question_practice_skill_updated.correct_answer) {
            if ((question.answer_type === 'answer.slider' || question.answer_type === 'answer.range' || question.answer_type === 'answer.number_line') && typeof question_practice_skill_updated.correct_answer === 'object') {
                if (!question_practice_skill_updated.correct_answer.hasOwnProperty('min') || !question_practice_skill_updated.correct_answer.min) {
                    dispatch(ErrorMessageAction('Error! Please set Min correct answer for question #' + question.number + '.'));
                    errors++;
                } else if (!question_practice_skill_updated.correct_answer.hasOwnProperty('max') || !question_practice_skill_updated.correct_answer.max) {
                    dispatch(ErrorMessageAction('Error! Please set Max correct answer for question #' + question.number + '.'));
                    errors++;
                } else if (!isNumeric(question_practice_skill_updated.correct_answer.min)) {
                    dispatch(ErrorMessageAction('Error! Min correct answer for question #' + question.number + ' must be numeric.'));
                    errors++;
                } else if (!isNumeric(question_practice_skill_updated.correct_answer.max)) {
                    dispatch(ErrorMessageAction('Error! Max correct answer for question #' + question.number + ' must be numeric.'));
                    errors++;
                }
            }
        }

        return errors === 0;
    }

    if (!question_practice_skill_updated) {
        return null;
    }
    if (!selected_practice_id) {
        return null;
    }

    return (
        <form
            onSubmit={event => event.preventDefault()}
            id={"question_practice_skill-form-" + question.code}
            className='question_practice_skill-form'
        >
            <div className='bordered mb-4'>
                <h3 className='d-flex justify-content-between'>
                    <HTMLContent
                        html_str={contentUtils.processStringContent(question.title, {convert_spelling: false})}/>
                    <i className={'fas ' + (updated ? 'fa-radiation-alt text-danger' : 'fa-check text-success')}/>
                </h3>
                <div className="row">
                    <div className="col-sm-6">
                        <label className='form-label'>MP Category</label>
                        <SkillSelector question_practice_skill={question_practice_skill_updated}/>
                    </div>
                    <div className="col-sm-6">
                        <div className='row'>
                            <div className="col-sm-6">
                                <label className='form-label'>DOK(Optional)</label>
                                <div>
                                    <select
                                        className='little'
                                        value={question_practice_skill_updated && question_practice_skill_updated.dok ? question_practice_skill_updated.dok : ''}
                                        onChange={event => {
                                            const val = event.target.value;
                                            const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                                            question_practice_skills_updated_x[question_practice_skills_updated_x.findIndex(r => r.question_code === question.code && r.practice_id === selected_practice_id)].dok = !isNaN(parseInt(val)) ? parseInt(val) : '';
                                            dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                                        }}
                                    >
                                        <option value=''>None</option>
                                        <option value={1}>1 - Recall and Reproduction</option>
                                        <option value={2}>2 - Application</option>
                                        <option value={3}>3 - Strategic Thinking and Reasoning</option>
                                        <option value={4}>4 - Extended Thinking</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label className='form-label'>Domain(Optional)</label>
                                <select
                                    className='little'
                                    value={question_practice_skill_updated && question_practice_skill_updated.domain ? question_practice_skill_updated.domain : ''}
                                    onChange={event => {
                                        const val = event.target.value;
                                        const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                                        question_practice_skills_updated_x[question_practice_skills_updated_x.findIndex(r => r.question_code === question.code && r.practice_id === selected_practice_id)].domain = val;
                                        dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                                    }}
                                >
                                    <option value=''>None</option>
                                    {topics.map(topic => {
                                        return (
                                            <option key={topic} value={topic}>{topic}</option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <label className='form-label'>Skill</label>
                        <textarea
                            className="form-control"
                            value={question_practice_skill_updated.skill}
                            onChange={event => {
                                const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                                question_practice_skills_updated_x[question_practice_skills_updated_x.findIndex(r => r.question_code === question.code && r.practice_id === selected_practice_id)].skill = event.target.value;
                                dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                            }}
                            placeholder="Skill"
                            rows={4}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className='form-label'>Correct answer</label>
                        <CorrectAnswer question={question}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <label className='form-label'>Example solution</label>
                        <CKEditor
                            id={question.code + '-' + selected_practice_id}
                            editor={ClassicEditor}
                            config={{
                                toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'insertTable', 'undo', 'redo']
                            }}
                            data={question_practice_skill_updated.example_solution}
                            onChange={(event, editor) => {
                                if (editor.getData() !== question_practice_skill_updated.example_solution) {
                                    const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                                    question_practice_skills_updated_x[question_practice_skills_updated_x.findIndex(r => r.question_code === question.code && r.practice_id === selected_practice_id)].example_solution = editor.getData();
                                    dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                                }
                            }}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className='form-label'>Notes</label>
                        <textarea
                            className="form-control"
                            value={question_practice_skill_updated.notes ? question_practice_skill_updated.notes : ''}
                            onChange={event => {
                                const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                                question_practice_skills_updated_x[question_practice_skills_updated_x.findIndex(r => r.question_code === question.code && r.practice_id === selected_practice_id)].notes = event.target.value;
                                dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                            }}
                            placeholder="Notes"
                            rows={4}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className='col-sm-6'>
                        <label className='form-label'>Depends on</label>
                        <MultipleSelector
                            id={'question_practice_skill-depends-' + question.code}
                            trigger={(
                                <div
                                    className='bordered little'
                                >{Array.isArray(question_practice_skill_updated.questions) && question_practice_skill_updated.questions.length > 0 ? questions.filter(q => question_practice_skill_updated.questions.includes(q.code)).map(q => 'Q' + q.number).join(', ') : 'Please select'}</div>
                            )}
                            callback={selected => {
                                const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                                question_practice_skills_updated_x[question_practice_skills_updated_x.findIndex(r => r.question_code === question_practice_skill_updated.question_code && r.practice_id === selected_practice_id)].questions = JSON.parse(JSON.stringify(selected));
                                dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                            }}
                            item_name='Question'
                            items={questions.filter(q => parseInt(q.number) < parseInt(question.number)).map(q => ({
                                code: q.code,
                                label: 'Q' + q.number
                            }))}
                            item_id_key={'code'}
                            selected={Array.isArray(question_practice_skill_updated.questions) ? question_practice_skill_updated.questions : []}
                            disabled={questions.filter(q => parseInt(q.number) < parseInt(question.number)).length <= 0}
                        />
                    </div>
                </div>
                <Points question_practice_skill_updated={question_practice_skill_updated}/>
            </div>
            <div className='d-flex justify-content-between'>
                {renderSaveButton()}
                {renderResetButton()}
                {renderDeleteButton()}
            </div>
        </form>
    );
}

UpdateRubricForm.propTypes = {
    question: PropTypes.shape({
        code: PropTypes.string.isRequired,
        answer_type: PropTypes.string.isRequired,
        answer_component_data: PropTypes.object
    }).isRequired,
    loadQuestionPracticeSkills: PropTypes.func.isRequired,
    updated: PropTypes.bool.isRequired
}

export default UpdateRubricForm;
