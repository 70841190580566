import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {
    AutocompleteMultiple,
    Checkbox,
    DatePicker,
    DistrictDropdown,
    LoadSchoolsAction,
    LoadUsersAction,
    MultipleSelector,
    SchoolDropdownItem,
    UserDropdownItem
} from "mindsets-js-sdk";

const Filters = function (
    {
        action,
        practice_code, setPracticeCode,
        class_codes, setClassCodes,
        with_bonus, setWithBonus,
        status, setStatus,
        intervals, setIntervals,
        selected_challenges, setSelectedChallenges,
        selected_challenge_versions, setSelectedChallengeVersions,
        selected_schools, setSelectedSchools,
        mode, setMode,
        source, setSource,
        after, setAfter,
        before, setBefore,
        district_id, setDistrictId,
        selected_users, setSelectedUsers
    }
) {
    const dispatch = useDispatch();
    const practices = useSelector(state => state.impact_practices);
    const challenge_codes = useSelector(state => state.challenge_codes);
    const challenge_versions = useSelector(state => state.challenge_versions);
    const challenge_codes_new = JSON.parse(JSON.stringify(challenge_codes));
    const challenge_versions_new = JSON.parse(JSON.stringify(challenge_versions));
    const selected_schools_new = JSON.parse(JSON.stringify(selected_schools));
    const selected_users_new = JSON.parse(JSON.stringify(selected_users));

    function renderTriggerLabel() {
        let label = 'Please select';
        if (selected_challenge_versions.length > 0) {
            label = selected_challenge_versions.map(selected_challenge_version_id => {
                const challenge_version = challenge_versions.find(challenge_version => challenge_version.id === selected_challenge_version_id);

                return challenge_version.name + ' v.' + challenge_version.version;
            }).sort().join(', ');
        }

        return label;
    }

    return (
        <div className='section'>
            <div className='d-flex flex-column mb-2'>
                <label>By Challenge(s)(Optional)</label>
                <MultipleSelector
                    item_name='challenges'
                    item_id_key='code'
                    trigger={<div
                        className='bordered little'>{selected_challenges && selected_challenges.length > 0 ? selected_challenges.map(selected_challenges_code => challenge_codes.find(challenge_code => challenge_code.code === selected_challenges_code).latest_challenge_version_name).sort().join(', ') : 'Please select'}</div>}
                    items={challenge_codes_new.sort((a, b) => {
                        if (a.latest_challenge_version_name < b.latest_challenge_version_name) {
                            return -1;
                        }
                        if (a.latest_challenge_version_name > b.latest_challenge_version_name) {
                            return 1;
                        }

                        return 0;
                    }).map(challenge_code => ({
                        code: challenge_code.code,
                        label: challenge_code.latest_challenge_version_name
                    }))}
                    selected={JSON.parse(JSON.stringify(selected_challenges))}
                    callback={setSelectedChallenges}
                    showCloseButton={true}
                    showAllOption={false}
                    matchDropdownWidth={false}
                    allowHtml={false}
                />
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>By Challenge Version(s)(Optional)</label>
                <MultipleSelector
                    item_name='challenge_versions'
                    trigger={<div className='bordered little'>{renderTriggerLabel()}</div>}
                    items={challenge_versions_new.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }

                        return 0;
                    }).map(challenge_version => ({
                        id: challenge_version.id,
                        label: challenge_version.name + ' v.' + challenge_version.version
                    }))}
                    selected={JSON.parse(JSON.stringify(selected_challenge_versions))}
                    callback={setSelectedChallengeVersions}
                    showCloseButton={true}
                    showAllOption={false}
                    matchDropdownWidth={false}
                    allowHtml={false}
                />
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>By School(s)(Optional)</label>
                <AutocompleteMultiple
                    debounced={true}
                    fetchItems={q => dispatch(LoadSchoolsAction({q}))}
                    selected_items={selected_schools_new}
                    onSelect={selected => {
                        const index = selected_schools_new.findIndex(selected_school_new => selected_school_new.id === selected.id);
                        if (index >= 0) {
                            selected_schools_new.splice(index, 1);
                        } else {
                            selected_schools_new.push(selected);
                        }
                        setSelectedSchools(selected_schools_new);
                    }}
                    renderItem={school => <SchoolDropdownItem item={school}/>}
                    persist_value={true}
                    placeholder='Start typing a school name'
                    with_reset={true}
                    onReset={() => setSelectedSchools([])}
                    id='impact-schools'
                />
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>By District(optional)</label>
                <DistrictDropdown onSelect={district => {
                    if (district) {
                        setDistrictId(district.id);
                    } else {
                        setDistrictId(null);
                    }

                    return district ? district.name : '';
                }} placeholder='Start typing name' id='impact-study-district'/>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>By Class codes(comma separated, optional)</label>
                <textarea value={class_codes} onChange={event => setClassCodes(event.target.value)}/>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>By Teacher(s)(optional)</label>
                <AutocompleteMultiple
                    debounced={true}
                    fetchItems={q => dispatch(LoadUsersAction({q}))}
                    selected_items={selected_users_new}
                    onSelect={selected => {
                        const index = selected_users_new.findIndex(user => user.id === selected.id);
                        if (index >= 0) {
                            selected_users_new.splice(index, 1);
                        } else {
                            selected_users_new.push(selected);
                        }
                        setSelectedUsers(selected_users_new);
                    }}
                    renderItem={user => <UserDropdownItem item={user}/>}
                    persist_value={true}
                    placeholder='Start typing user name'
                    with_reset={true}
                    onReset={() => setSelectedUsers([])}
                    id='impact-teachers'
                />
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Classes created after(optional)</label>
                <div>
                    <DatePicker
                        date={after}
                        onChange={setAfter}
                        with_reset={true}/>
                </div>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Classes created before(optional)</label>
                <div>
                    <DatePicker
                        date={before}
                        onChange={setBefore}
                        with_reset={true}/>
                </div>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Practice*</label>
                <select
                    value={practice_code}
                    onChange={event => setPracticeCode(event.target.value)}
                >
                    <option value='' disabled={true}>Select one</option>
                    {practices.map(practice => {
                        return (
                            <option key={practice.id} value={practice.code}>{practice.name}</option>
                        );
                    })}
                </select>
            </div>
            <div className='d-flex flex-column mb-2 flex-gap-5'>
                <div>
                    <Checkbox
                        checked={with_bonus}
                        toggle={() => setWithBonus(!with_bonus)}
                        label='Include Bonus questions'
                    />
                </div>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Grading status(optional)</label>
                <select
                    value={status}
                    onChange={event => setStatus(event.target.value)}
                >
                    <option value=''>Any</option>
                    <option value='requested'>Requested</option>
                    <option value='started'>Started</option>
                    <option value='finished'>Finished</option>
                </select>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Intervals</label>
                <select
                    value={intervals}
                    onChange={event => setIntervals(parseInt(event.target.value))}
                >
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                </select>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Mode</label>
                <div className='d-flex mode-buttons'>
                    <div className={'mode-button ' + (mode === 'achievement' ? 'mode-selected' : '')}>
                        <button className='big inactive' onClick={() => {
                            if (mode === 'potential') {
                                setMode('achievement');
                            }
                        }}>Achievement
                        </button>
                    </div>
                    <div className={'mode-button ' + (mode === 'potential' ? 'mode-selected' : '')}>
                        <button className='big inactive' onClick={() => {
                            if (mode === 'achievement') {
                                setMode('potential');
                            }
                        }}>Potential
                        </button>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Grading Source</label>
                <div className='d-flex mode-buttons'>
                    <div className={'mode-button ' + (source === 'manual' ? 'mode-selected' : '')}>
                        <button className='big inactive' onClick={() => {
                            if (source !== 'manual') {
                                setSource('manual');
                            }
                        }}>Manual
                        </button>
                    </div>
                    <div className={'mode-button ' + (source === 'rbag' ? 'mode-selected' : '')}>
                        <button className='big inactive' onClick={() => {
                            if (source !== 'rbag') {
                                setSource('rbag');
                            }
                        }}>Auto-grading - Rubric
                        </button>
                    </div>
                    <div className={'mode-button ' + (source === 'mlag' ? 'mode-selected' : '')}>
                        <button className='big inactive' onClick={() => {
                            if (source !== 'mlag') {
                                setSource('mlag');
                            }
                        }}>Auto-grading - ML
                        </button>
                    </div>
                </div>
            </div>
            <button
                className='large'
                onClick={event => {
                    event.preventDefault();
                    action();
                }}
            >GET
            </button>
        </div>
    );
}

Filters.propTypes = {
    action: PropTypes.func.isRequired,
    class_codes: PropTypes.string.isRequired,
    setClassCodes: PropTypes.func.isRequired,
    practice_code: PropTypes.string.isRequired,
    setPracticeCode: PropTypes.func.isRequired,
    with_bonus: PropTypes.bool.isRequired,
    setWithBonus: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
    setStatus: PropTypes.func.isRequired,
    intervals: PropTypes.number.isRequired,
    setIntervals: PropTypes.func.isRequired,
    selected_challenges: PropTypes.array.isRequired,
    setSelectedChallenges: PropTypes.func.isRequired,
    selected_challenge_versions: PropTypes.array.isRequired,
    setSelectedChallengeVersions: PropTypes.func.isRequired,
    selected_schools: PropTypes.array.isRequired,
    setSelectedSchools: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(['achievement', 'potential']).isRequired,
    setMode: PropTypes.func.isRequired,
    source: PropTypes.oneOf(['manual', 'rbag', 'mlag']).isRequired,
    setSource: PropTypes.func.isRequired,
    after: PropTypes.number,
    setAfter: PropTypes.func.isRequired,
    before: PropTypes.number,
    setBefore: PropTypes.func.isRequired,
    district_id: PropTypes.number,
    setDistrictId: PropTypes.func.isRequired,
    selected_users: PropTypes.array.isRequired,
    setSelectedUsers: PropTypes.func.isRequired
}

export default Filters;
