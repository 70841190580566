import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    authUtils,
    accessConstants,
    ErrorMessageAction,
    countriesConstants,
    SetSchoolAction,
    CreateSchoolAction,
    LoadingAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import {LoadCurriculaAction} from "../../actions/school_actions";
import StateField from "../../common/StateField";
import DistrictField from "./components/DistrictField";
import SubmitButtons from "./components/SubmitButtons";

export default function () {
    const me = useSelector(state => state.me);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const school = useSelector(state => state.school);
    const curricula = useSelector(state => state.schools_curricula);
    useEffect(() => {
        if (!curricula.length) {
            dispatch(LoadCurriculaAction());
        }

        return () => dispatch(SetSchoolAction({}));
    }, []);
    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_SCHOOLS])) {
        return (
            <NoAccess/>
        );
    }
    const [updated, setUpdated] = useState(false);
    const validate = function () {
        let errors = 0;

        if (!school.name || school.name === '') {
            dispatch(ErrorMessageAction('Error! Please specify the school name'));
            errors++;
        }

        if (!school.country || school.country === '') {
            dispatch(ErrorMessageAction('Error! Please select country.'));
            errors++;
        }

        if (!school.location || school.location === '') {
            dispatch(ErrorMessageAction('Error! Please specify the location of the school'));
            errors++;
        }

        if (!school.phone || school.phone === '') {
            dispatch(ErrorMessageAction('Error! Please specify the phone number of the school'));
            errors++;
        }

        return errors === 0;
    }

    return (
        <div className='container section'>
            <h1>Create new School {school.name ? school.name : ''}</h1>
            <form onSubmit={event => {
                event.preventDefault();
                if (validate()) {
                    dispatch(CreateSchoolAction(school)).then(() => {
                        setUpdated(false);
                        navigate(-1);
                    }).catch(error => {
                        dispatch(LoadingAction(false));
                        dispatch(ErrorMessageAction(error));
                        console.error(error);
                    });
                }
            }} autoComplete="off" id="create-school-form">
                <div className="row">
                    <label htmlFor="autoload-field" className="col-sm-2 col-form-label">School Name *</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="location"
                            placeholder="School Name"
                            value={school.name ? school.name : ''}
                            onChange={event => {
                                let school_new = JSON.parse(JSON.stringify(school));
                                school_new.name = event.target.value;
                                dispatch(SetSchoolAction(school_new));
                                setUpdated(true);
                            }}/>
                    </div>
                </div>

                <div className="row">
                    <label htmlFor="country" className="col-sm-2 col-form-label">Country *</label>
                    <div className="col-sm-10">
                        <select
                            className="form-control"
                            id="country"
                            value={school.country ? school.country : ''}
                            onChange={event => {
                                let school_new = JSON.parse(JSON.stringify(school));
                                school_new.country = event.target.value;
                                dispatch(SetSchoolAction(school_new));
                                setUpdated(true);
                            }}
                        >
                            <option value='' disabled={true}>SELECT COUNTRY</option>
                            {Object.keys(countriesConstants.COUNTRIES).map(country_code => {
                                return (
                                    <option
                                        key={country_code}
                                        value={country_code}
                                    >{countriesConstants.COUNTRIES[country_code]}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>

                <DistrictField setUpdated={setUpdated}/>

                <div className="row">
                    <label htmlFor="location" className="col-sm-2 col-form-label">Location *</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="location"
                            placeholder="Location"
                            value={school.location ? school.location : ''}
                            onChange={event => {
                                let school_new = JSON.parse(JSON.stringify(school));
                                school_new.location = event.target.value;
                                dispatch(SetSchoolAction(school_new));
                                setUpdated(true);
                            }}/>
                    </div>
                </div>

                <div className="row">
                    <label htmlFor="city" className="col-sm-2 col-form-label">City</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="city"
                            placeholder="City"
                            value={school.city ? school.city : ''}
                            onChange={event => {
                                let school_new = JSON.parse(JSON.stringify(school));
                                school_new.city = event.target.value;
                                dispatch(SetSchoolAction(school_new));
                                setUpdated(true);
                            }}/>
                    </div>
                </div>

                <div className="row">
                    <label htmlFor="zip" className="col-sm-2 col-form-label">Zip Code</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="zip"
                            placeholder="Zip Code"
                            value={school.zip ? school.zip : ''}
                            onChange={event => {
                                let school_new = JSON.parse(JSON.stringify(school));
                                school_new.zip = event.target.value;
                                dispatch(SetSchoolAction(school_new));
                                setUpdated(true);
                            }}/>
                    </div>
                </div>

                <div className="row">
                    <label htmlFor="phone" className="col-sm-2 col-form-label">Phone *</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="phone"
                            placeholder="Phone"
                            value={school.phone ? school.phone : ''}
                            onChange={event => {
                                let school_new = JSON.parse(JSON.stringify(school));
                                school_new.phone = event.target.value;
                                dispatch(SetSchoolAction(school_new));
                                setUpdated(true);
                            }}/>
                    </div>
                </div>

                <StateField
                    entity={school}
                    onChange={value => {
                        let school_new = JSON.parse(JSON.stringify(school));
                        school_new.state = value;
                        dispatch(SetSchoolAction(school_new));
                        if (!updated) {
                            setUpdated(true);
                        }
                    }}
                    outer_class_name='row'
                    inner_class_name='col-sm-10'
                    label_class_name='col-sm-2 col-form-label'
                />

                <div className="row">
                    <label htmlFor="state" className="col-sm-2 col-form-label">Curriculum</label>
                    <div className="col-sm-10">
                        <select
                            className="form-control"
                            id="state"
                            value={school.curriculum_id ? school.curriculum_id : ''}
                            onChange={event => {
                                let school_new = JSON.parse(JSON.stringify(school));
                                school_new.curriculum_id = isNaN(parseInt(event.target.value)) ? '' : parseInt(event.target.value);
                                dispatch(SetSchoolAction(school_new));
                                setUpdated(true);
                            }}
                        >
                            <option value=''>Select curriculum</option>
                            {curricula.map(curriculum => {
                                return (
                                    <option key={curriculum.id} value={curriculum.id}>{curriculum.name}</option>
                                );
                            })}
                        </select>
                    </div>
                </div>

                <div>
                    <label htmlFor="notes" className="">Notes/Comments</label>
                    <textarea
                        className="form-control"
                        id="notes"
                        placeholder="Notes"
                        value={school.notes ? school.notes : ''}
                        onChange={event => {
                            let school_new = JSON.parse(JSON.stringify(school));
                            school_new.notes = event.target.value;
                            dispatch(SetSchoolAction(school_new));
                            setUpdated(true);
                        }}/>
                </div>

                <SubmitButtons updated={updated}/>
            </form>
        </div>
    );
}
