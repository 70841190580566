import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

function Row({item, row_number}) {
    const practices = useSelector(state => state.practices);

    function renderSkills() {
        return practices.map(practice => {
            let has = false
            if (Array.isArray(item.question_practice_skills) && item.question_practice_skills.length) {
                if (item.question_practice_skills.filter(qps => qps.practice_skill.practice_id === practice.id).length) {
                    has = true;
                }
            }
            return (
                <div key={practice.id} className={has ? 'text-success' : 'text-danger'}>{practice.name}</div>
            )
        });
    }

    if (!practices || !practices.length) {
        return null;
    }

    return (
        <tr className='questions-table-row'>
            <td>{row_number}</td>
            <td>{item.id}</td>
            <td>
                <h5>{item.number ? 'Q' + item.number : 'Number Unknown'} | {item.answer_type ? item.answer_type : 'Type Unknown'}</h5>
                <h6>{item.code ? item.code : 'Code Unknown'}</h6>
                <div>{item.challenge_name} v.{item.challenge_version}</div>
            </td>
            <td>
                <div dangerouslySetInnerHTML={{__html: item.title}}/>
            </td>
            <td>{renderSkills()}</td>
            <td></td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        number: PropTypes.string,
        answer_type: PropTypes.string,
        title: PropTypes.string,
        challenge_version: PropTypes.number.isRequired,
        challenge_name: PropTypes.string.isRequired,
        question_practice_skills: PropTypes.arrayOf(PropTypes.shape({
            practice_skill: PropTypes.shape({
                practice_id: PropTypes.number.isRequired
            }).isRequired
        })),
    }).isRequired,
    row_number: PropTypes.number.isRequired
};

export default Row;
