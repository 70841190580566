import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    statesConstants,
    accessConstants,
    authUtils,
    ErrorMessageAction,
    countriesConstants,
    LoadingAction,
    SetDistrictAction,
    CreateDistrictAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import SubmitButtons from "./components/SubmitButtons";
import StateField from "../../common/StateField";

export default function () {
    const me = useSelector(state => state.me);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const district = useSelector(state => state.district);
    useEffect(() => {
        return () => dispatch(SetDistrictAction({}));
    }, []);
    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_DISTRICTS])) {
        return (
            <NoAccess/>
        );
    }
    const [updated, setUpdated] = useState(false);
    const validate = function () {
        let errors = 0;

        if (!district.name || district.name === '') {
            dispatch(ErrorMessageAction('Error! Please specify the name of the district.'));
            errors++;
        }

        if (!district.country || district.country === '') {
            dispatch(ErrorMessageAction('Error! Please select country.'));
            errors++;
        }

        if (district.country && '' !== district.country && statesConstants.STATES.hasOwnProperty(district.country)) {
            if (!district.state || '' === district.state) {
                dispatch(ErrorMessageAction('Error! Please specify the state of the district.'));
                errors++;
            }
        }

        if (!district.city || district.city === '') {
            dispatch(ErrorMessageAction('Error! Please specify the city of the district.'));
            errors++;
        }

        return errors === 0;
    }

    return (
        <div className='container section'>
            <h1>Create new District {district.name ? district.name : ''}</h1>
            <form onSubmit={event => {
                event.preventDefault();
                if (validate()) {
                    dispatch(CreateDistrictAction(district))
                        .then(() => navigate(-1))
                        .catch(error => {
                            dispatch(LoadingAction(false));
                            dispatch(ErrorMessageAction(error));
                            console.error(error);
                        });
                }
            }} autoComplete="off" id="district-form">
                <div className="row">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Name"
                            value={district.name ? district.name : ''}
                            onChange={event => {
                                let district_new = JSON.parse(JSON.stringify(district));
                                district_new.name = event.target.value;
                                dispatch(SetDistrictAction(district_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}/>
                    </div>
                </div>
                <div className="row">
                    <label htmlFor="country" className="col-sm-2 col-form-label">Country</label>
                    <div className="col-sm-10">
                        <select
                            className="form-control"
                            id="country"
                            value={district.country ? district.country : ''}
                            onChange={event => {
                                let district_new = JSON.parse(JSON.stringify(district));
                                district_new.country = event.target.value;
                                dispatch(SetDistrictAction(district_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}
                        >
                            <option value='' disabled={true}>SELECT COUNTRY</option>
                            {Object.keys(countriesConstants.COUNTRIES).map(country_code => {
                                return (
                                    <option
                                        key={country_code}
                                        value={country_code}
                                    >{countriesConstants.COUNTRIES[country_code]}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <StateField
                    entity={district}
                    onChange={value => {
                        let district_new = JSON.parse(JSON.stringify(district));
                        district_new.state = value;
                        dispatch(SetDistrictAction(district_new));
                        if (!updated) {
                            setUpdated(true);
                        }
                    }}
                    outer_class_name='row'
                    inner_class_name='col-sm-10'
                    label_class_name='col-sm-2 col-form-label'
                />
                <div className="row">
                    <label htmlFor="city" className="col-sm-2 col-form-label">City</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="city"
                            placeholder="City"
                            value={district.city ? district.city : ''}
                            onChange={event => {
                                let district_new = JSON.parse(JSON.stringify(district));
                                district_new.city = event.target.value;
                                dispatch(SetDistrictAction(district_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}/>
                    </div>
                </div>
                <div>
                    <label htmlFor="notes" className="">Notes/Comments</label>
                    <textarea
                        className="form-control"
                        id="notes"
                        placeholder="Notes"
                        value={district.notes ? district.notes : ''}
                        onChange={event => {
                            let district_new = JSON.parse(JSON.stringify(district));
                            district_new.notes = event.target.value;
                            dispatch(SetDistrictAction(district_new));
                            if (!updated) {
                                setUpdated(true);
                            }
                        }}/>
                </div>

                <SubmitButtons updated={updated}/>
            </form>
        </div>
    );
}
