import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, AddClassCodesToSelectionAction, getRowNumber} from "mindsets-js-sdk";

import Row from './Row';

export default function () {
    const dispatch = useDispatch();
    const selected_lesson_codes = useSelector(state => state.selected_lesson_codes);
    const selected_lesson_codes_sorted = JSON.parse(JSON.stringify(selected_lesson_codes)).sort();
    const items = useSelector(state => state.lessons);
    const current = useSelector(state => state.lessons_current);
    const limit = useSelector(state => state.lessons_limit);

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="text-center">
                    <th scope="col">
                        <Checkbox
                            checked={selected_lesson_codes_sorted.join(',') === items.map(item => item.code).sort().join(',')}
                            toggle={() => {
                                let array = [];
                                if (selected_lesson_codes_sorted.join(',') !== items.map(item => item.code).sort().join(',')) {
                                    array = items.map(item => item.code);
                                }
                                dispatch(AddClassCodesToSelectionAction(array));
                            }}
                        />
                    </th>
                    <th scope="col">No</th>
                    <th scope="col">ID</th>
                    <th scope="col">Code</th>
                    <th scope="col">Teacher</th>
                    <th scope="col"># students</th>
                    <th scope="col"># Co-teachers</th>
                    <th scope="col">Status</th>
                    <th scope="col">Mode</th>
                    <th scope="col">AK Shown</th>
                    <th scope="col">Open</th>
                    <th scope="col">Hidden</th>
                    <th scope="col">Done</th>
                    <th scope="col">Latest Activity</th>
                    <th scope="col">Assessment status</th>
                    <th scope="col">Grade</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {items.map((item, index) => {
                    return (
                        <Row
                            key={item.id}
                            item={item}
                            row_number={getRowNumber(index, current, limit)}
                        />
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};
