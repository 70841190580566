import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    accessConstants,
    authUtils,
    filterConstants,
    DateRangeSelector,
    datesConstants,
    ChallengeDropdown,
    SchoolDropdown,
    DistrictDropdown, UserDropdown, Checkbox
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import {LoadedStreamsAction, LoadImpactDataAction, LoadPracticesAction} from "../../actions/impact_actions";
import Results from "./components/Results";
import DownloadButton from "./components/DownloadButton";

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const practices = useSelector(state => state.impact_practices);
    const [practice_id, setPracticeId] = useState(null);
    const [challenge_version_id, setChallengeVersionId] = useState(null);
    const [school_id, setSchoolId] = useState(null);
    const [district_id, setDistrictId] = useState(null);
    const [user_id, setUserId] = useState(null);
    const dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 7);
    dateFrom.setUTCHours(0, 0, 0, 0);
    const [created_at_from, setCreatedAtFrom] = useState(dateFrom.getTime() / 1000);
    const dateTill = new Date();
    dateTill.setUTCHours(23, 59, 59, 999);
    const [created_at_till, setCreatedAtTill] = useState(dateTill.getTime() / 1000);
    const [class_codes, setClassCodes] = useState('');
    const [with_bonus, setWithBonus] = useState(false);
    useEffect(() => {
        dispatch(LoadPracticesAction());
    }, []);
    if (!me || !practices.length) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_REQUEST_STATISTICS])) {
        return (
            <NoAccess/>
        );
    }
    const prepareOptions = function () {
        let options = {};
        options['filters[challenge_version_id][0]'] = filterConstants.EXPRESSION_EQUAL + '=' + challenge_version_id;
        if (school_id) {
            options['filters[school_id][0]'] = filterConstants.EXPRESSION_EQUAL + '=' + school_id;
        }
        if (district_id) {
            options['filters[district_id][0]'] = filterConstants.EXPRESSION_EQUAL + '=' + district_id;
        }
        if (practice_id) {
            options['filters[practice_id][0]'] = filterConstants.EXPRESSION_EQUAL + '=' + practice_id;
        }
        if (user_id) {
            options['filters[teacher_id][0]'] = filterConstants.EXPRESSION_EQUAL + '=' + user_id;
        }
        if (created_at_from) {
            options['filters[lesson_created_at][0]'] = filterConstants.EXPRESSION_GTE + '=' + created_at_from;
        }
        if (created_at_till) {
            options['filters[lesson_created_at][1]'] = filterConstants.EXPRESSION_LTE + '=' + created_at_till;
        }
        if (class_codes.length) {
            options['filters[class_codes][0]'] = filterConstants.EXPRESSION_IN + '=' + class_codes;
        }
        options['filters[with_bonus][0]'] = with_bonus ? filterConstants.EXPRESSION_TRUE : filterConstants.EXPRESSION_FALSE;

        return options;
    }

    return (
        <div className='container section'>
            <h1>Get data for Impact Study</h1>
            <form onSubmit={event => {
                event.preventDefault();
                dispatch(LoadImpactDataAction(prepareOptions()));
            }} autoComplete="off">
                <div className="row">
                    <div className="col-sm-3">
                        <label>Challenge(required)</label>
                        <ChallengeDropdown onSelect={challenge_version => {
                            if (challenge_version) {
                                setChallengeVersionId(challenge_version.id);
                            } else {
                                setChallengeVersionId(null);
                            }
                            dispatch(LoadedStreamsAction([]));

                            return challenge_version ? challenge_version.name : '';
                        }} unversioned={true} placeholder='Type name or code' id='impact-challenge'/>
                    </div>
                    <div className="col-sm-3">
                        <label htmlFor='practice'>Practice(required)</label>
                        <select
                            className="form-control"
                            id="practice"
                            value={practice_id ? practice_id : ''}
                            onChange={event => setPracticeId(parseInt(event.target.value))}
                        >
                            <option value='' disabled={true}>Please select a Practice</option>
                            {practices.map(practice => {
                                return (
                                    <option value={practice.id} key={practice.id}>{practice.name}</option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-sm-3">
                        <label>School(optional)</label>
                        <div className='d-flex flex-gap-10 align-items-center'>
                            <SchoolDropdown
                                onSelect={school => {
                                    if (school) {
                                        setSchoolId(school.id);
                                    } else {
                                        setSchoolId(null);
                                    }
                                    dispatch(LoadedStreamsAction([]));

                                    return school ? school.name : '';
                                }}
                                match_dropdown_width={false}
                                placeholder='Start typing name' id='impact-school' district_id={district_id}
                            />
                            {school_id ? (
                                <i
                                    className='fas fa-times-circle clickable fa-2x'
                                    onClick={() => setSchoolId('')}
                                />
                            ) : null}
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <label>District(optional)</label>
                        <div className='d-flex flex-gap-10 align-items-center'>
                            <DistrictDropdown onSelect={district => {
                                if (district) {
                                    setDistrictId(district.id);
                                } else {
                                    setDistrictId(null);
                                }
                                dispatch(LoadedStreamsAction([]));

                                return district ? district.name : '';
                            }} placeholder='Start typing name' id='impact-district'/>
                            {district_id ? (
                                <i
                                    className='fas fa-times-circle clickable fa-2x'
                                    onClick={() => setDistrictId('')}
                                />
                            ) : null}
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <label>Teacher(optional)</label>
                        <div className='d-flex flex-gap-10 align-items-center'>
                            <UserDropdown onSelect={user => {
                                if (user) {
                                    setUserId(user.id);
                                } else {
                                    setUserId(null);
                                }
                                dispatch(LoadedStreamsAction([]));

                                return user ? user.fname + ' ' + user.lname : '';
                            }} persona='teacher' school_id={school_id}
                                          placeholder='First, last name OR email but not together'/>
                            {user_id ? (
                                <i
                                    className='fas fa-times-circle clickable fa-2x'
                                    onClick={() => setUserId('')}
                                />
                            ) : null}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <label>Class Codes(optional)</label>
                        <div className='d-flex flex-gap-10 align-items-center'>
                            <textarea
                                value={class_codes}
                                onChange={event => setClassCodes(event.target.value)}
                                rows={4}
                            />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className='d-flex flex-gap-10 align-items-center'>
                            <Checkbox checked={with_bonus} toggle={() => setWithBonus(!with_bonus)} label='With bonus'/>
                        </div>
                    </div>
                </div>
                <div className='my-3'>
                    <DateRangeSelector
                        id={'impact-page-filter'}
                        onUpdate={data => {
                            setCreatedAtFrom(data.from);
                            setCreatedAtTill(data.till);
                            dispatch(LoadedStreamsAction([]));
                        }}
                        as='buttons'
                        default={datesConstants.DATE_RANGE_LAST_7_DAYS}
                        from={created_at_from}
                        till={created_at_till}
                    />
                </div>

                <div>
                    <button type="submit" className="button" disabled={!challenge_version_id || !practice_id}>GET
                    </button>
                </div>
            </form>
            <Results/>
            <DownloadButton practice_id={practice_id}/>
        </div>
    );
}
