import React from 'react';
import {useSelector} from "react-redux";

import NavigationPages from "./components/NavigationPages";
import File from "./components/File";
import ChallengeForm from "./components/ChallengeForm";
import Page from "./components/Page";
import Templates from "./components/Templates";
import Modes from "./components/Modes";
import JSONEditor from "./components/JSONEditor";

import './challenge_builder.scss';

export const CONTENT_COMPONENTS = [
    {
        id: 'click_to_show',
        name: 'Click to show',
        icon: 'mouse-pointer',
        structure: {
            type: "click_to_show",
            data: {
                html: "",
                button_label: ""
            }
        }
    },
    {
        id: 'comparison',
        name: 'Comparison',
        icon: 'less-than-equal',
        structure: {
            type: "comparison",
            data: {
                text: "",
                bubble_background: "",
                bubble_direction: "",
                imageURL: "",
                image_name: "",
                top: "",
                bubble_corner: "",
                animated_gif_url: "",
                animated_git_name: ""
            }
        }
    },
    {
        id: 'divider',
        name: 'Divider',
        icon: 'divide',
        structure: {
            type: "divider",
            data: {}
        }
    },
    {
        id: 'graph',
        name: 'Graph',
        icon: 'chart-area',
        structure: {
            type: "graph",
            data: {}
        }
    },
    {
        id: 'graphing',
        name: 'Graphing',
        icon: 'chart-bar',
        structure: {
            type: "graphing",
            config: {
                ggb: {
                    material_id: ""
                }
            }
        }
    },
    {
        id: 'iframe',
        name: 'Iframe',
        icon: 'crop-alt',
        structure: {
            type: "iframe",
            data: {
                src: ""
            }
        }
    },
    {
        id: 'image',
        name: 'Image',
        icon: 'image',
        structure: {
            type: "image",
            data: {
                src: ""
            }
        }
    },
    {
        id: 'info_tabs',
        name: 'Info tabs',
        icon: 'info',
        structure: {
            type: "info_tabs",
            data: {
                items: []
            }
        }
    },
    {
        id: 'link',
        name: 'Link',
        icon: 'link',
        structure: {
            type: "link",
            data: {
                image: '',
                title: '',
                link: ''
            }
        }
    },
    {
        id: 'number_line',
        name: 'Number line',
        icon: 'sliders-h',
        structure: {
            type: "number_line",
            data: {
                numberPoints: [],
                first_num: 0,
                last_num: 0,
                initial_pos: 0,
                increment_interval: 1,
                snap_divisions_per_interval: 10
            }
        }
    },
    {
        id: 'question',
        name: 'Question',
        icon: 'question',
        structure: {
            type: "question",
            data: {
                question_number: "",
                question: "",
                hint: ""
            }
        }
    },
    {
        id: 'speech',
        name: 'Speech',
        icon: 'comments',
        structure: {
            type: "speech",
            config: {
                direction: "horizontal"
            },
            data: {
                image: {
                    src: ""
                },
                right: {
                    text: ""
                }
            }
        }
    },
    {
        id: 'spacer',
        name: 'Spacer',
        icon: 'grip-lines',
        structure: {
            type: "spacer"
        }
    },
    {
        id: 'step',
        name: 'Step',
        icon: 'shoe-prints',
        structure: {
            type: "step",
            config: {
                width: ""
            },
            data: {
                image_url: "",
                title: "",
                description: ""
            }
        }
    },
    {
        id: 'text',
        name: 'Text',
        icon: 'keyboard',
        structure: {
            type: "text",
            data: {
                html: ""
            }
        }
    },
    {
        id: 'table',
        name: 'Table',
        icon: 'table',
        structure: {
            type: "table",
            data: {
                html: ""
            }
        }
    },
    {
        id: 'data_tabs',
        name: 'Data Tabs',
        icon: 'table',
        structure: {
            type: "data_tabs",
            data: {
                items: [
                ]
            }
        }
    }
];
export const ANSWER_COMPONENTS = [
    {
        id: 'answer.choice_multiple',
        name: 'Choice multiple',
        icon: 'list',
        structure: {
            type: "answer.choice_multiple",
            config: {
                auto_grading: false,
                answer: []
            },
            data: {
                question_number: "",
                options: {}
            }
        }
    },
    {
        id: 'answer.choice_single',
        name: 'Choice single',
        icon: 'list-ul',
        structure: {
            type: "answer.choice_single",
            config: {
                auto_grading: true,
                answer: ""
            },
            data: {
                question_number: "",
                options: {}
            }
        }
    },
    {
        id: 'answer.dnd',
        name: 'Drag&Drop',
        icon: 'grip-horizontal',
        structure: {
            type: "answer.dnd",
            data: {
                question_number: "",
                answer_groups: [],
                answer_layout: []
            }
        }
    },
    {
        id: 'answer.expression',
        name: 'Expression',
        icon: 'square-root-alt',
        structure: {
            type: "answer.expression",
            data: {
                question_number: "",
                prefill: null,
                button_sets: [
                    ""
                ]
            }
        }
    },
    {
        id: 'answer.expression_multi',
        name: 'Expression Multi',
        icon: 'square-root-alt',
        structure: {
            type: "answer.expression_multi",
            config: {
                max_line: 7
            },
            data: {
                question_number: "",
                prefill: [],
                button_sets: [
                    ""
                ]
            }
        }
    },
    {
        id: 'answer.graphing',
        name: 'Graphing',
        icon: 'chart-bar',
        structure: {
            type: "answer.graphing",
            config: {
                ggb: {
                    material_id: ""
                }
            },
            data: {
                question_number: ""
            }
        }
    },
    {
        id: 'answer.number_line',
        name: 'Number Line',
        icon: 'sliders-h',
        structure: {
            type: "answer.number_line",
            data: {
                question_number: "",
                numberPoints: [],
                first_num: 1,
                last_num: 1,
                initial_pos: 1,
                increment_interval: 0.1,
                snap_divisions_per_interval: 10
            }
        }
    },
    {
        id: 'poll.block',
        name: 'Poll Block',
        icon: 'poll',
        structure: {
            type: "poll.block",
            config: {},
            data: {
                title: "",
                description: "",
                poll_options: []
            }
        }
    },
    {
        id: 'poll.inline',
        name: 'Poll Inline',
        icon: 'poll-h',
        structure: {
            type: "poll.inline",
            config: {},
            data: {
                question_number: "",
                poll_options: []
            }
        }
    },
    {
        id: 'answer.slider',
        name: 'Slider single',
        icon: 'sliders-h',
        structure: {
            type: "answer.slider",
            data: {
                question_number: "",
                min: "1",
                max: "10",
                step: "1",
                unit: "",
                value: "3"
            }
        }
    },
    {
        id: 'answer.range',
        name: 'Slider multiple(range)',
        icon: 'sliders-h',
        structure: {
            type: "answer.range",
            data: {
                question_number: "",
                min: "1",
                max: "10",
                step: "1",
                unit: "",
                valueMin: "3",
                valueMax: "7"
            }
        }
    },
    {
        id: 'spreadsheet',
        name: 'Spreadsheet',
        icon: 'table',
        structure: {
            type: "spreadsheet",
            data: {
                question_number: "",
                name: "",
                headers: [],
                data: []
            }
        }
    },
    {
        id: 'answer.textarea',
        name: 'Textarea',
        icon: 'keyboard',
        structure: {
            type: "answer.textarea",
            data: {
                question_number: ""
            }
        }
    },
    {
        id: 'answer.fraction',
        name: 'Fraction',
        icon: 'divide',
        structure: {
            type: "answer.fraction",
            config: {
                max_length: 15
            },
            data: {
                question_number: "",
                items: []
            }
        }
    },
    {
        id: 'answer.matching',
        name: 'Matching',
        icon: 'grip-vertical',
        structure: {
            type: "answer.matching",
            data: {
                question_number: "",
                items_left: {
                    header_name: "",
                    items: []
                },
                items_right: {
                    header_name: "",
                    items: []
                }
            }
        }
    },
    {
        id: 'answer.number_only',
        name: 'Number only',
        icon: 'list-ol',
        structure: {
            type: "answer.number_only",
            config: {
                max_length: 15
            },
            data: {
                question_number: "",
                items: []
            }
        }
    },
    {
        id: 'answer.selector',
        name: 'Selector',
        icon: 'hand-pointer',
        structure: {
            type: "answer.selector",
            config: {
                operator: "sum"
            },
            data: {
                question_number: "",
                title_1: "",
                description_1: "",
                title_2: "",
                description_2: "",
                unit_prefix: "",
                unit_suffix: "",
                value_prefix: "",
                value_suffix: "",
                amount_maximum: 10,
                error_amount_too_big: "",
                items: []
            }
        }
    }
];
export const CHALLENGE_PAGES = {
    intro: {
        type: "intro",
        reference: "",
        data: {
            answer_required: false,
            title: ""
        }
    },
    welcome: {
        type: "welcome",
        reference: "",
        data: {
            title: "Welcome...",
            answer_required: false,
            card_content: {
                rows: [
                    {
                        components: [
                            {
                                type: "text",
                                data: {
                                    html: "X Questions | Y Bonus | Z Polls"
                                }
                            }
                        ]
                    },
                    {
                        components: [
                            {
                                type: "text",
                                data: {
                                    html: ""
                                }
                            }
                        ]
                    },
                    {
                        components: [
                            {
                                type: "step",
                                config: {
                                    width: "400px"
                                },
                                data: {
                                    image_url: "https://assets.go.mindsets.com/challenge-content/X/Y.png",
                                    title: "STEP 1",
                                    description: ""
                                }
                            },
                            {
                                type: "step",
                                config: {
                                    width: "400px"
                                },
                                data: {
                                    image_url: "https://assets.go.mindsets.com/challenge-content/X/Y.png",
                                    title: "STEP 2",
                                    description: ""
                                }
                            }
                        ]
                    },
                    {
                        components: [
                            {
                                type: "step",
                                config: {
                                    width: "400px"
                                },
                                data: {
                                    image_url: "https://assets.go.mindsets.com/challenge-content/X/Y.png",
                                    title: "STEP 3",
                                    description: ""
                                }
                            },
                            {
                                type: "step",
                                config: {
                                    width: "400px",
                                    is_bonus: true
                                },
                                data: {
                                    image_url: "https://assets.go.mindsets.com/challenge-content/X/Y.png",
                                    title: "BONUS",
                                    description: ""
                                }
                            }
                        ]
                    }
                ]
            }
        }
    },
    instruction: {
        type: "instruction",
        reference: "",
        data: {
            title: "",
            answer_required: false,
            card_content: {
                rows: [
                    {
                        components: [
                            {
                                type: "text",
                                data: {
                                    html: ""
                                }
                            }
                        ]
                    }
                ]
            }
        }
    },
    poll: {
        type: "poll",
        reference: "",
        data: {
            title: "Class Vote and Poll",
            answer_required: true,
            card_content: {
                rows: [
                    {
                        components: [
                            {
                                type: "poll.block",
                                data: {
                                    title: "Class Vote and Poll",
                                    description: "",
                                    poll_options: [
                                        {
                                            title: "",
                                            option: "",
                                            description: ""
                                        },
                                        {
                                            title: "",
                                            option: "",
                                            description: ""
                                        },
                                        {
                                            title: "",
                                            option: "",
                                            description: ""
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                ]
            }
        }
    },
    winner: {
        type: "winner",
        reference: "",
        data: {
            title: "The overall class result is...",
            answer_required: false
        }
    },
    question: {
        type: "question",
        reference: "",
        data: {
            title: "",
            answer_required: true,
            card_content: {
                rows: [
                    {
                        components: [
                            {
                                type: "question",
                                data: {
                                    question_number: "",
                                    question: "",
                                    hint: "",
                                    code: ""
                                }
                            }
                        ]
                    },
                    {
                        components: [
                            {
                                type: "poll.inline",
                                data: {
                                    question_number: "",
                                    poll_options: [
                                        {
                                            title: "",
                                            option: "",
                                            description: ""
                                        },
                                        {
                                            title: "",
                                            option: "",
                                            description: ""
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                ]
            }
        }
    },
    question_bonus: {
        type: "question",
        reference: "",
        data: {
            title: "",
            answer_required: true,
            is_bonus: true,
            card_content: {
                rows: [
                    {
                        components: [
                            {
                                type: "text",
                                data: {
                                    html: ""
                                }
                            }
                        ]
                    },
                    {
                        components: [
                            {
                                type: "question",
                                data: {
                                    question_number: "",
                                    question: "",
                                    hint: "",
                                    code: ""
                                }
                            }
                        ]
                    },
                    {
                        components: [
                            {
                                type: "answer.textarea",
                                data: {
                                    question_number: ""
                                }
                            }
                        ]
                    }
                ]
            }
        }
    },
    complete: {
        type: "complete",
        reference: "",
        data: {
            title: "Challenge Completed",
            answer_required: false,
            card_content: {
                rows: [
                    {
                        components: [
                            {
                                type: "text",
                                data: {
                                    html: ""
                                }
                            }
                        ]
                    },
                    {
                        components: [
                            {
                                type: "step",
                                config: {
                                    width: "400px",
                                    show_tick: true
                                },
                                data: {
                                    image_url: "https://assets.go.mindsets.com/challenge-content/X/Y.png",
                                    title: "STEP 1",
                                    description: ""
                                }
                            },
                            {
                                type: "step",
                                config: {
                                    width: "400px",
                                    show_tick: true
                                },
                                data: {
                                    image_url: "https://assets.go.mindsets.com/challenge-content/X/Y.png",
                                    title: "STEP 2",
                                    description: ""
                                }
                            }
                        ]
                    },
                    {
                        components: [
                            {
                                type: "step",
                                config: {
                                    width: "400px",
                                    show_tick: true
                                },
                                data: {
                                    image_url: "https://assets.go.mindsets.com/challenge-content/X/Y.png",
                                    title: "STEP 3",
                                    description: ""
                                }
                            },
                            {
                                type: "step",
                                config: {
                                    width: "400px",
                                    is_bonus: true,
                                    show_next: true
                                },
                                data: {
                                    image_url: "https://assets.go.mindsets.com/challenge-content/X/Y.png",
                                    title: "BONUS",
                                    description: ""
                                }
                            }
                        ]
                    }
                ]
            }
        }
    },
    unlock: {
        type: "unlock",
        reference: "",
        data: {
            title: "Enter your bonus questions..."
        }
    },
    final: {
        type: "final",
        reference: "",
        data: {
            title: "Bonus finished!",
            answer_required: false,
            is_bonus: false
        }
    }
}

export default function () {
    const mode = useSelector(state => state.challenge_builder_mode);

    function renderContent() {
        if (mode === 'dev') {
            return (
                <JSONEditor/>
            );
        }

        return (
            <>
                <NavigationPages/>
                <Page/>
            </>
        );
    }

    return (
        <div className='challenge-builder-page section'>
            <h4 className="display-4">Challenge Content Builder</h4>
            <div className='d-flex flex-gap-20 align-items-center'>
                <div className='button-set'>
                    <File/>
                    <Templates/>
                    <Modes/>
                </div>
                <ChallengeForm/>
            </div>
            {renderContent()}
        </div>
    );
}
