import React from 'react';
import MindsetsJsSDK, {
    ListPage,
    filterConstants,
    ErrorMessageAction,
    Autocomplete,
} from "mindsets-js-sdk";

import Table from "./Table";
import CreateButton from "./CreateButton";
import {LoadDashboardsAction} from "../../actions/dashboard_actions";

export default function () {
    return (
        <>
            <h1>Dashboards</h1>
            <ListPage
                action={options => LoadDashboardsAction(Object.assign({
                    'selectors[0]': 'project'
                }, options))}
                filter_columns={[
                    {
                        key: 'title',
                        name: 'Title',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'project_id',
                        name: 'Project',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <Autocomplete
                                    fetchItems={name => {
                                        let options = {}
                                        options['filters[name][0]'] = filterConstants.EXPRESSION_CONTAINS + '=' + name;

                                        return MindsetsJsSDK().API.get('projects', options)
                                            .then(response => response.items)
                                            .catch(error => {
                                                console.error(error);
                                                dispatch(ErrorMessageAction('Failed loading projects'));
                                            });
                                    }}
                                    onSelect={project => {
                                        if (project) {
                                            setFilterValue(project.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return project ? project.name : '';
                                    }}
                                    renderItem={item => <div className="autocomplete-dropdown-menu-item-title">{item.name}</div>}
                                    id={'dashboard-project-filter'}
                                />
                            );
                        },
                        multiple: false
                    }
                ]}
                sort_columns={[
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'title',
                        name: 'Title'
                    },
                    {
                        key: 'project_id',
                        name: 'Project ID'
                    }
                ]}
                entity={'dashboards'}
                create_button={<CreateButton/>}
                permissions={['can_see_projects']}
                default_order_by='title'
            >
                <Table/>
            </ListPage>
        </>
    );
}
