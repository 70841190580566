import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CustomDropdown, ErrorMessageAction, SuccessMessageAction} from "mindsets-js-sdk";

import {
    SetChallengeBuilderChallengeCodeAction,
    SetChallengeBuilderChallengeColorAction,
    SetChallengeBuilderChallengeNameAction,
    SetChallengeBuilderChallengePagesAction,
    SetChallengeBuilderImportRawStringAction,
    SetChallengeBuilderIndexAction,
    SetChallengeBuilderSelectedComponentIndexAction,
    SetChallengeBuilderSelectedRowIndexAction
} from "../../../actions/challenge_actions";

export default function () {
    const dispatch = useDispatch();
    const hiddenFileInput = useRef(null);

    const import_string = useSelector(state => state.challenge_builder_import_string);
    const challenge_code = useSelector(state => state.challenge_builder_challenge_code);
    const challenge_name = useSelector(state => state.challenge_builder_challenge_name);
    const challenge_color = useSelector(state => state.challenge_builder_challenge_color);
    const challenge_pages = useSelector(state => state.challenge_builder_challenge_pages);

    function getJson() {
        let json = '{"challenge":"';
        json += challenge_name;
        json += '","entities":';
        json += JSON.stringify(challenge_pages.map((page, index) => {
            return {
                ...page,
                reference: challenge_code + '-' + (index + 1)
            }
        }));
        json += '}';

        return json;
    }

    function readJson(string) {
        let is_valid = true;
        let challenge;
        try {
            challenge = JSON.parse(string);
        } catch (error) {
            is_valid = false;
        }
        is_valid = validateChallengeInput(challenge, is_valid);
        if (is_valid) {
            return challenge;
        }
        dispatch(ErrorMessageAction('Error when reading data'));
    }

    function validateChallengeInput(challenge, is_valid = true) {
        if (is_valid && !challenge) {
            is_valid = false;
        }
        if (is_valid && !challenge.hasOwnProperty('entities')) {
            is_valid = false;
        }
        if (is_valid && !Array.isArray(challenge.entities)) {
            is_valid = false;
        }
        if (is_valid && challenge.entities.length < 1) {
            is_valid = false;
        }
        if (is_valid) {
            challenge.entities.map(page => {
                if (!page.type || !page.data || !page.reference) {
                    is_valid = false;
                }
            });
        }

        return is_valid;
    }

    function renderExportModal() {
        let json = getJson();

        return (
            <div className="modal fade" id='export-challenge-modal' tabIndex="-1" role="dialog"
                 aria-labelledby="exportChallengeModal" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exportChallengeModalLabel">Export challenge content</h5>
                            <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <textarea
                                id='export-json'
                                className='clear'
                                value={json}
                                onChange={() => {
                                }}
                                rows={30}
                            />
                        </div>
                        <div className="modal-footer">
                            <button className='button blue' onClick={() => {
                                const copyTextarea = document.querySelector('#export-json');
                                copyTextarea.focus();
                                copyTextarea.select();
                                try {
                                    document.execCommand('copy');
                                    dispatch(SuccessMessageAction('Copied successfully'));
                                } catch (err) {
                                    dispatch(ErrorMessageAction('Oops, unable to copy'))
                                }
                            }}
                            >Copy
                            </button>
                            <button type="button" className="button default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderImportModal() {
        let is_valid = true;
        let challenge;
        try {
            challenge = JSON.parse(import_string);
        } catch (error) {
            is_valid = false;
        }
        is_valid = validateChallengeInput(challenge, is_valid);

        return (
            <div className="modal fade" id='import-challenge-json-modal' tabIndex="-1" role="dialog"
                 aria-labelledby="importChallengeModal" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="importChallengeModalLabel">Import challenge content</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <textarea
                                className={'form-control ' + (is_valid ? '' : 'is-invalid')}
                                value={import_string}
                                placeholder='Post/write content here'
                                onChange={event => dispatch(SetChallengeBuilderImportRawStringAction(event.target.value))}
                                rows={30}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="button default" data-bs-dismiss="modal">Close</button>
                            <button
                                className="button"
                                disabled={!is_valid}
                                onClick={() => {
                                    if (is_valid) {
                                        dispatch(SetChallengeBuilderChallengeNameAction(challenge.challenge));
                                        dispatch(SetChallengeBuilderChallengeCodeAction(''));
                                        dispatch(SetChallengeBuilderChallengeColorAction('#ffc110'));
                                        dispatch(SetChallengeBuilderChallengePagesAction(challenge.entities));
                                        bootstrap.Modal.getInstance(document.getElementById("import-challenge-json-modal")).hide();
                                    }
                                }}>Import
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderExport() {
        return (
            <a
                data-toggle="modal"
                data-target={'#export-challenge-modal'}
            >Export
            </a>
        );
    }

    function renderImport() {
        return (
            <a
                data-bs-toggle="modal"
                data-bs-target={'#import-challenge-json-modal'}
            >Import
            </a>
        );
    }

    function renderSave() {
        return (
            <a onClick={() => {
                if (challenge_code) {
                    let json = getJson();
                    let a = document.createElement('a');
                    const blob = new Blob([json], {'type': 'application/json'});
                    a.href = window.URL.createObjectURL(blob);
                    a.download = challenge_code + '.json';
                    a.click();
                    window.close();
                } else {
                    dispatch(ErrorMessageAction('Please set challenge code'));
                }
            }}>Save to file</a>
        );
    }

    function renderUpload() {
        return (
            <div>
                <a onClick={() => hiddenFileInput.current.click()}>
                    Upload from file
                </a>
                <input
                    type="file"
                    onChange={event => {
                        const file = event.target.files[0];

                        let reader = new FileReader();

                        // Closure to capture the file information.
                        reader.onload = (function (theFile) {
                            return function (e) {
                                const challenge = readJson(e.target.result);
                                if (challenge) {
                                    dispatch(SetChallengeBuilderChallengeNameAction(challenge.challenge));
                                    if (challenge.code) {
                                        dispatch(SetChallengeBuilderChallengeCodeAction(challenge.code));
                                    }
                                    dispatch(SetChallengeBuilderChallengeColorAction(challenge.color ?? '#ffc110'));
                                    dispatch(SetChallengeBuilderChallengePagesAction(challenge.entities));
                                    dispatch(SuccessMessageAction('Loaded from your browser\'s storage.'));
                                } else {
                                    dispatch(SuccessMessageAction('Storage is empty or data is corrupted.'));
                                }
                            };
                        })(file);
                        // Read in the image file as a data URL.
                        reader.readAsText(file);


                    }}
                    ref={hiddenFileInput}
                    style={{display: 'none'}}
                />
            </div>
        );
    }

    function renderMemorize() {
        return (
            <a onClick={() => {
                if (challenge_pages.length > 0) {
                    let json = '{';
                    json += '"challenge":"' + challenge_name + '"';
                    json += ',"entities":' + JSON.stringify(challenge_pages.map((page, index) => {
                        return {
                            ...page,
                            reference: challenge_code + '-' + (index + 1)
                        }
                    }));
                    json += ',"color":"' + challenge_color + '"';
                    json += ',"code":"' + challenge_code + '"';
                    json += '}';

                    window.localStorage.setItem('challenge', json);
                    dispatch(SuccessMessageAction('Saved temporarily in your browser.'));
                } else {
                    dispatch(ErrorMessageAction('Nothing to save.'));
                }
            }}>Memorise</a>
        );
    }

    function renderRestore() {
        return (
            <a onClick={() => {
                const challenge = readJson(window.localStorage.getItem('challenge'));
                if (challenge) {
                    dispatch(SetChallengeBuilderChallengeNameAction(challenge.challenge));
                    dispatch(SetChallengeBuilderChallengeCodeAction(challenge.code));
                    dispatch(SetChallengeBuilderChallengeColorAction(challenge.color ?? '#ffc110'));
                    dispatch(SetChallengeBuilderChallengePagesAction(challenge.entities));
                    dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
                    dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
                    dispatch(SuccessMessageAction('Loaded from your browser\'s storage.'));
                } else {
                    dispatch(SuccessMessageAction('Storage is empty or data is corrupted.'));
                }
            }}>Restore</a>
        );
    }

    function renderClearMemory() {
        return (
            <a onClick={() => {
                window.localStorage.removeItem('challenge');
                dispatch(SetChallengeBuilderChallengeNameAction(''));
                dispatch(SetChallengeBuilderChallengeCodeAction(''));
                dispatch(SetChallengeBuilderChallengeColorAction('#ffc110'));
                dispatch(SetChallengeBuilderChallengePagesAction([]));
            }}>Clear memory</a>
        );
    }

    function renderClear() {
        return (
            <a onClick={() => {
                dispatch(SetChallengeBuilderChallengeNameAction(''));
                dispatch(SetChallengeBuilderChallengeCodeAction(''));
                dispatch(SetChallengeBuilderChallengeColorAction('#ffc110'));
                dispatch(SetChallengeBuilderChallengePagesAction([]));
                dispatch(SetChallengeBuilderIndexAction(0));
            }}>Clear</a>
        );
    }

    return (
        <>
            <CustomDropdown
                trigger={(
                    <button className='first'>File</button>
                )}
                no_wrap={true}
                open_on_mouse_over={true}
                close_on_mouse_leave={true}
                items={{
                    export: renderExport(),
                    import: renderImport(),
                    save: renderSave(),
                    upload: renderUpload(),
                    memorize: renderMemorize(),
                    restore: renderRestore(),
                    clear_memory: renderClearMemory(),
                    clear: renderClear(),
                }}
            />
            {renderExportModal()}
            {renderImportModal()}
        </>
    );
}
