import React from 'react';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {contentUtils, CustomDropdown} from "mindsets-js-sdk";
import {useDispatch, useSelector} from "react-redux";

import {
    SetChallengeBuilderChallengePagesAction,
    SetChallengeBuilderIndexAction,
    SetChallengeBuilderSelectedComponentIndexAction,
    SetChallengeBuilderSelectedRowIndexAction
} from "../../../actions/challenge_actions";
import {CHALLENGE_PAGES} from "../index";

export default function () {
    const dispatch = useDispatch();
    const page_index = useSelector(state => state.challenge_builder_index);
    const pages = useSelector(state => state.challenge_builder_challenge_pages);
    const mode = useSelector(state => state.challenge_builder_mode);

    function reorder(startIndex, endIndex) {
        let pages_new = JSON.parse(JSON.stringify(pages));
        const [removed] = pages_new.splice(startIndex, 1);
        pages_new.splice(endIndex, 0, removed);

        return pages_new;
    }

    function onDragEnd(result) {
        if (!result || !result.draggableId) {
            return;
        }

        const {source, destination} = result;
        if (!destination || !destination.droppableId) {
            return;
        }

        if (destination.droppableId) {
            dispatch(SetChallengeBuilderChallengePagesAction(reorder(source.index, destination.index)));
        }

        return null;
    }

    function getPages() {
        const items = {}

        Object.keys(CHALLENGE_PAGES).filter(key => mode === 'pd' ? (['intro', 'question', 'complete', 'unlock', 'final'].includes(key)) : true).map(key => {
            items[key] = renderPage(key);
        });

        return items;
    }

    function renderPage(key) {
        return (
            <a onClick={() => {
                let challenge_pages = JSON.parse(JSON.stringify(pages));
                challenge_pages.splice(page_index + 1, 0, CHALLENGE_PAGES[key]);
                dispatch(SetChallengeBuilderChallengePagesAction(challenge_pages));
                if (pages.length > 0) {
                    dispatch(SetChallengeBuilderIndexAction(page_index + 1));
                }
                dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
                dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
            }}>{_.upperFirst(key)}</a>
        );
    }

    return (
        <div className='d-flex justify-content-between mb-2'>
            <div className='d-flex flex-grow-1' style={{overflowX: 'clip', minWidth: 0}}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable_pages" type='pages' direction="horizontal">
                        {provided => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className='d-flex flex-row'
                                style={{
                                    overflowX: 'scroll'
                                }}
                            >
                                {pages.map((page, index) => {
                                    return (
                                        <Draggable
                                            draggableId={'page_drag_' + index.toString()}
                                            key={'page_drag_' + index}
                                            index={index}
                                        >
                                            {providedDrag => (
                                                <div
                                                    ref={providedDrag.innerRef}
                                                    {...providedDrag.draggableProps}
                                                    {...providedDrag.dragHandleProps}
                                                    className='navigation-card'
                                                >
                                                    <div
                                                        className={'card clickable position-relative ' + (page_index === index ? 'navigation-card-selected' : '')}
                                                        onClick={() => {
                                                            dispatch(SetChallengeBuilderIndexAction(index));
                                                            dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
                                                            dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
                                                        }}
                                                    >
                                                        <i
                                                            className='fas fa-trash text-danger clickable position-absolute'
                                                            style={{right: '2px', top: '2px'}}
                                                            onClick={event => {
                                                                event.stopPropagation();
                                                                if (confirm(`Are you sure you want to delete the page #${index + 1}? Data will be lost permanently.`)) {
                                                                    let pages_new = JSON.parse(JSON.stringify(pages));
                                                                    pages_new.splice(index, 1);
                                                                    dispatch(SetChallengeBuilderChallengePagesAction(pages_new));
                                                                }
                                                            }}
                                                        />
                                                        <div className='card-body'>
                                                            <div>Page #{index + 1}</div>
                                                            <h5>{contentUtils.capitalizeFirstLetter(page.type ? page.type : 'N/A')}</h5>
                                                        </div>
                                                        <i
                                                            className='fas fa-clone text-info clickable position-absolute'
                                                            style={{right: '2px', bottom: '2px'}}
                                                            onClick={event => {
                                                                event.stopPropagation();
                                                                let pages_new = JSON.parse(JSON.stringify(pages));
                                                                pages_new.splice(index, 0, _.cloneDeep(pages_new[index]));
                                                                dispatch(SetChallengeBuilderChallengePagesAction(pages_new));
                                                                dispatch(SetChallengeBuilderIndexAction(index + 1));
                                                            }}
                                                        />
                                                    </div>
                                                    {providedDrag.placeholder}
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className='navigation-card narrow'>
                    <div className={'card position-relative '}>
                        <CustomDropdown
                            trigger={(
                                <div className='card-body d-flex justify-content-center align-items-center'>
                                    <i className='fas fa-plus'/>
                                </div>
                            )}
                            no_wrap={true}
                            open_on_mouse_over={true}
                            close_on_mouse_leave={true}
                            items={getPages()}
                            use_arrow={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
