import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    accessConstants,
    authUtils, Autocomplete,
    ErrorMessageAction,
    LoadingAction, LoadProjectsAction
} from "mindsets-js-sdk";


import NoAccess from "../../common/NoAccess";
import SubmitButtons from "./components/SubmitButtons";
import {CreateDashboardAction} from "../../actions/dashboard_actions";

export default function () {
    const me = useSelector(state => state.me);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dashboard, setDashboard] = useState({});
    useEffect(() => {
        return () => setDashboard(null);
    }, []);
    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_CONTRACTS])) {
        return (
            <NoAccess/>
        );
    }
    const [updated, setUpdated] = useState(false);
    const validate = function () {
        let errors = 0;

        if (!dashboard.title || dashboard.title === '') {
            dispatch(ErrorMessageAction('Error! Please specify the title of the dashboard.'));
            errors++;
        }

        if (!dashboard.type || dashboard.type === '') {
            dispatch(ErrorMessageAction('Error! Please select type.'));
            errors++;
        }

        if (!dashboard.project_id || dashboard.project_id === '') {
            dispatch(ErrorMessageAction('Error! Please select Project ID.'));
            errors++;
        }
        if (dashboard.class_codes) {
            dashboard.class_codes.split(',').map(class_code => {
                if (!/^[a-fA-F]{4}[0-9]{2}$/.test(class_code.trim())) {
                    dispatch(ErrorMessageAction('Invalid Class Code format: ' + class_code));
                    errors++;
                }
            });
        }

        return errors === 0;
    }

    return (
        <div className='container section'>
            <h1>Create new Dashboard {dashboard.title ? dashboard.title : ''}</h1>
            <form onSubmit={event => {
                event.preventDefault();
                if (validate()) {
                    dispatch(CreateDashboardAction(dashboard))
                        .then(() => navigate(-1))
                        .catch(error => {
                            dispatch(LoadingAction(false));
                            dispatch(ErrorMessageAction(error));
                            console.error(error);
                        });
                }
            }} autoComplete="off" id="dashboard-form">
                <div className="row">
                    <label htmlFor="Project" className="col-sm-2 col-form-label">Project</label>
                    <div className="col-sm-10">
                        <Autocomplete
                            id='dashboard-projects'
                            placeholder='Project name'
                            fetchItems={q => dispatch(LoadProjectsAction({
                                q,
                                order_by: 'name',
                                order_direction: 'asc'
                            }))}
                            onSelect={project => {
                                let dashboard_new = JSON.parse(JSON.stringify(dashboard));
                                if (project) {
                                    dashboard_new.project_id = project.id;
                                } else {
                                    dashboard_new.project_id = null;
                                }
                                setDashboard(dashboard_new);
                                if (!updated) {
                                    setUpdated(true);
                                }

                                return project ? project.name : '';
                            }}
                            renderItem={project => (
                                <div className="autocomplete-dropdown-menu-item-title">{project.name}</div>)}
                            persist_value={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">Title</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            placeholder="Title"
                            value={dashboard.title ? dashboard.title : ''}
                            onChange={event => {
                                let dashboard_new = JSON.parse(JSON.stringify(dashboard));
                                dashboard_new.title = event.target.value;
                                setDashboard(dashboard_new);
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}/>
                    </div>
                </div>
                <div className="row">
                    <label htmlFor="type" className="col-sm-2 col-form-label">Type</label>
                    <div className="col-sm-10">
                        <select
                            className="form-control"
                            id="type"
                            value={dashboard.type ? dashboard.type : ''}
                            onChange={event => {
                                let dashboard_new = JSON.parse(JSON.stringify(dashboard));
                                dashboard_new.type = event.target.value;
                                setDashboard(dashboard_new);
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}
                        >
                            <option value='' disabled={true}>SELECT TYPE</option>
                            <option value='TYPE 1'>TYPE 1</option>
                            <option value='TYPE 2'>TYPE 2</option>
                            <option value='TYPE 3'>TYPE 3</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <label htmlFor="description" className="col-sm-2 col-form-label">Description(Optional)</label>
                    <div className="col-sm-10">
                        <textarea
                            className="form-control"
                            id="description"
                            placeholder="Description"
                            value={dashboard.description ? dashboard.description : ''}
                            onChange={event => {
                                let dashboard_new = JSON.parse(JSON.stringify(dashboard));
                                dashboard_new.description = event.target.value;
                                setDashboard(dashboard_new);
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}/>
                    </div>
                </div>
                <div className="row">
                    <label htmlFor="class_codes" className="col-sm-2 col-form-label">Class Codes(Optional)</label>
                    <div className="col-sm-10">
                        <textarea
                            className="form-control"
                            id="class_codes"
                            placeholder="Class Codes"
                            value={dashboard.class_codes ? dashboard.class_codes : ''}
                            onChange={event => {
                                let dashboard_new = JSON.parse(JSON.stringify(dashboard));
                                dashboard_new.class_codes = event.target.value ? event.target.value : null;
                                setDashboard(dashboard_new);
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}/>
                    </div>
                </div>

                <SubmitButtons updated={updated}/>
            </form>
        </div>
    );
}
