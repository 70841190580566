import React from 'react';
import PropTypes from 'prop-types';
import {HTMLContent, contentUtils} from "mindsets-js-sdk";

const Vote = function ({response, question}) {
    const poll = question.poll;
    if (poll) {
        const poll_option_index = poll.poll_options.findIndex(poll_option => poll_option.id === response);
        if (poll_option_index !== -1) {
            const poll_option = poll.poll_options[poll_option_index];
            return (
                <div>
                    <div>Option {poll_option_index + 1}</div>
                    <HTMLContent
                        html_str={contentUtils.processStringContent(poll_option.option, {convert_spelling: false})}/>
                </div>
            );
        }
    }

    return null;
}

Vote.propTypes = {
    question: PropTypes.shape({
        poll: PropTypes.shape({
            poll_options: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                option: PropTypes.string.isRequired,
            })).isRequired,
        })
    }).isRequired,
    response: PropTypes.number
};

export default Vote;
