import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {ChallengeDropdown, DatePicker} from "mindsets-js-sdk";

const Filters = function (
    {
        action,
        practice_id,
        setPracticeId,
        setChallengeCode,
        lesson_code,
        setLessonCode,
        from, setFrom,
        till, setTill
    }
) {
    const practices = useSelector(state => state.responses_practices);

    return (
        <div className='section'>
            <div className='d-flex flex-column mb-2'>
                <label>Challenge</label>
                <ChallengeDropdown
                    onSelect={challenge_version => {
                        if(challenge_version) {
                            setChallengeCode(challenge_version.challenge_code);
                        } else {
                            setChallengeCode('');
                        }

                        return challenge_version ? challenge_version.name : '';
                    }}
                    id='ai-matching-rate'
                    with_reset={true}
                    onReset={() => setChallengeCode(null)}
                />
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Practice</label>
                <select
                    value={practice_id}
                    onChange={event => setPracticeId(parseInt(event.target.value))}
                >
                    <option value=''>All Practices</option>
                    {practices.map(practice => {
                        return (
                            <option key={practice.id} value={practice.id}>{practice.name}</option>
                        );
                    })}
                </select>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Class codes(comma separated)</label>
                <textarea value={lesson_code} onChange={event => setLessonCode(event.target.value)}/>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Date range(time of grading)</label>
                <div className="d-flex flex-gap-10">
                    <DatePicker
                        date={function () {
                            if (from && till) {
                                return [from, till];
                            }

                            return null;
                        }()}
                        onChange={timestamps => {
                            if (timestamps.from && timestamps.till) {
                                setFrom(timestamps.from);
                                setTill(timestamps.till);
                            }
                        }}
                        with_reset={true}
                        range={true}
                    />
                </div>
            </div>
            <button
                className='large'
                onClick={event => {
                    event.preventDefault();
                    action();
                }}
                disabled={!practice_id}
            >GET
            </button>
        </div>
    );
}

Filters.propTypes = {
    action: PropTypes.func.isRequired,
    challenge_version: PropTypes.object,
    setChallengeCode: PropTypes.func.isRequired,
    lesson_code: PropTypes.string,
    setLessonCode: PropTypes.func.isRequired,
    practice_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    setPracticeId: PropTypes.func.isRequired,
    from: PropTypes.number,
    till: PropTypes.number,
    setFrom: PropTypes.func.isRequired,
    setTill: PropTypes.func.isRequired
}

export default Filters;
