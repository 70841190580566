import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Draggable} from "react-beautiful-dnd";
import {contentUtils, HTMLContent} from "mindsets-js-sdk";

const AnswerOption = memo(function ({option, option_index, answer_group, extra_style}) {
    function getDraggableStyle(default_style) {
        const color_light = _.get(answer_group, 'color_light');
        const color_dark = _.get(answer_group, 'color_dark');
        return {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "10px",
            minHeight: "50px",
            minWidth: "50px",
            background: color_light,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: color_dark,
            borderRadius: "4px",
            ..._.get(answer_group, 'draggable_style'),
            ...default_style,
            ...extra_style
        }
    }

    return (
        <Draggable
            draggableId={option.dnd_id}
            index={option_index}
            type={answer_group.dnd_type}
            isDragDisabled={true}
        >
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getDraggableStyle(provided.draggableProps.style)}
                >
                    <HTMLContent
                        html_str={contentUtils.processStringContent(option.html, {
                            keep_html: true,
                            convert_math: true,
                            convert_spelling: false,
                        })}/>
                </div>
            )}
        </Draggable>
    );
});

AnswerOption.propTypes = {
    answer_group: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
    option_index: PropTypes.number.isRequired,
    extra_style: PropTypes.object
}

export default AnswerOption
