import MindsetsJsSDK from "mindsets-js-sdk";

export const SET_QUESTION_PRACTICE_SKILL_UPDATED = '[question_practice_skills] Set Question Practice Skills Updated';

export const LOADED_QUESTION_PRACTICE_SKILL_GROUPS = '[question_practice_skill_groups] Loaded Question Practice Skill groups';
export const LOADED_QUESTION_PRACTICE_SKILL_GROUPS_PAGES = '[question_practice_skill_groups] Loaded pages';
export const LOADED_QUESTION_PRACTICE_SKILL_GROUPS_TOTAL = '[question_practice_skill_groups] Loaded total';
export const LOADED_QUESTION_PRACTICE_SKILL_GROUPS_CURRENT = '[question_practice_skill_groups] Loaded current';
export const LOADED_QUESTION_PRACTICE_SKILL_GROUPS_LIMIT = '[question_practice_skill_groups] Loaded limit';

export const CreateQuestionPracticeSkillAction = (data, options) => dispatch => {
    return MindsetsJsSDK().API.post('question_practice_skills', data, options);
};
export const UpdateQuestionPracticeSkillAction = (id, data, options) => dispatch => {
    return MindsetsJsSDK().API.put(`question_practice_skills/${id}`, data, options);
};
export const DeleteQuestionPracticeSkillAction = id => dispatch => {
    return MindsetsJsSDK().API.delete(`question_practice_skills/${id}`);
};

export const SetQuestionPracticeSkillsUpdatedAction = payload => ({
    type: SET_QUESTION_PRACTICE_SKILL_UPDATED,
    payload
});

export const LoadQuestionPracticeSkillGroupsAction = options => dispatch => {
    return MindsetsJsSDK().API.get('question_practice_skills/groups', options)
        .then(response => {
            dispatch(LoadedQuestionPracticeSkillGroupsAction(response.items));
            dispatch(LoadedQuestionPracticeSkillGroupsCurrentAction(response.pages >= response.current ? response.current : 1));
            dispatch(LoadedQuestionPracticeSkillGroupsLimitAction(response.limit));
            dispatch(LoadedQuestionPracticeSkillGroupsPagesAction(response.pages));
            dispatch(LoadedQuestionPracticeSkillGroupsTotalAction(response.total));

            return response.items;
        });
};
export const LoadedQuestionPracticeSkillGroupsAction = payload => ({
    type: LOADED_QUESTION_PRACTICE_SKILL_GROUPS,
    payload
});
export const LoadedQuestionPracticeSkillGroupsPagesAction = payload => ({
    type: LOADED_QUESTION_PRACTICE_SKILL_GROUPS_PAGES,
    payload
});
export const LoadedQuestionPracticeSkillGroupsTotalAction = payload => ({
    type: LOADED_QUESTION_PRACTICE_SKILL_GROUPS_TOTAL,
    payload
});
export const LoadedQuestionPracticeSkillGroupsCurrentAction = payload => ({
    type: LOADED_QUESTION_PRACTICE_SKILL_GROUPS_CURRENT,
    payload
});
export const LoadedQuestionPracticeSkillGroupsLimitAction = payload => ({
    type: LOADED_QUESTION_PRACTICE_SKILL_GROUPS_LIMIT,
    payload
});