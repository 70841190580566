import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Droppable} from "react-beautiful-dnd";

import AnswerOption from './AnswerOption';


class AnswerGroup extends Component {
    getDroppableStyle() {
        const direction = _.get(this.props.answer_group, 'direction')
        const color_light = _.get(this.props.answer_group, 'color_light')
        return {
            display: "flex",
            flexDirection: (direction === 'vertical' ? "column" : "row"),
            flexWrap: "wrap",
            alignItems: "center",
            // justifyContent: "center",
            minWidth: "100px",
            minHeight: "100px",
            padding: "10px",
            background: "#FFFFFF",
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: color_light,
            borderRadius: "4px",
            ..._.get(this.props.answer_group, 'droppable_style')
        }
    }

    renderOptions() {
        const not_selected_options = _.filter(this.props.answer_group.options, ((option) => {
            return !_.find(this.props.answer_layout, (al) => {
                return _.get(al, 'content.dnd_id') === option.dnd_id
            })
        }))
        return not_selected_options.map((option, i) => {
            return (
                <AnswerOption
                    key={option.dnd_id}
                    answer_group={this.props.answer_group}
                    option={option}
                    option_index={i}
                />
            )
        })
    }

    render() {
        return (
            <div>
                <Droppable
                    droppableId={this.props.answer_group.dnd_type}
                    type={this.props.answer_group.dnd_type}
                    direction={this.props.answer_group.direction}
                >
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={this.getDroppableStyle()}
                        >
                            {this.renderOptions()}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        )
    }
}

AnswerGroup.propTypes = {
    answer_group: PropTypes.object.isRequired,
    answer_layout: PropTypes.array.isRequired
}

export default AnswerGroup
