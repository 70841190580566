import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    LoadingAction,
    DatePicker,
    ErrorMessageAction,
    MultipleSelector,
    LoadDistrictChallengeLocksAction,
    CreateDistrictChallengeLocksAction,
    DeleteChallengeLockAction,
    datesConstants
} from "mindsets-js-sdk";

export default function ({district_id}) {
    const dispatch = useDispatch();
    const challenge_locks = useSelector(state => state.district_challenge_locks);
    const challenge_codes = useSelector(state => state.challenge_codes);
    const [show, setShow] = useState(false);
    const [codes, setCodes] = useState('');
    const [selected_codes, setSelectedCodes] = useState([]);
    const [end_date, setEndDate] = useState(null);
    const challenge_codes_new = JSON.parse(JSON.stringify(challenge_codes));

    function action() {
        dispatch(LoadDistrictChallengeLocksAction(district_id, {'selectors[0]': 'latest_challenge_version_name', limit: 0}));
    }

    function validate() {
        let errors = 0;
        const ccs = challenge_codes.map(cc => cc.code);
        codes.split(',').map(c => c.trim()).filter(c => c !== '').map(c => {
            if (!ccs.includes(c)) {
                dispatch(ErrorMessageAction('Error! Please clean up. "' + c + '" is invalid'));
                errors++;
            }
        });

        return errors === 0;
    }

    useEffect(() => {
        action();

        return () => {
            setSelectedCodes([]);
            setCodes('');
        };
    }, []);

    return (
        <div className='challenge-locks'>
            <h3>District's Challenge Locks</h3>
            <div className='add-challenge-lock'>
                <button className='button no-wrap' onClick={() => setShow(!show)}>Add a new Challenge Lock
                </button>
                <div style={{display: (show ? 'initial' : 'none')}}>
                    <div className="section">
                        <div className="d-flex flex-gap-5">
                            <div>
                                <MultipleSelector
                                    callback={codes => {
                                        setSelectedCodes(codes);
                                        setCodes(codes.join(', '));
                                    }}
                                    item_name='challenge_codes'
                                    trigger={(
                                        <div
                                            className='bordered little'>{selected_codes.length > 0 ? selected_codes.length + ' selected' : 'Please select some'}</div>
                                    )}
                                    items={challenge_codes_new.sort((a, b) => {
                                        if (a.latest_challenge_version_name < b.latest_challenge_version_name) {
                                            return -1;
                                        }
                                        if (a.latest_challenge_version_name > b.latest_challenge_version_name) {
                                            return 1;
                                        }

                                        return 0;
                                    }).map(challenge_code => ({
                                        id: challenge_code.code,
                                        label: challenge_code.code + ' - ' + challenge_code.latest_challenge_version_name
                                    }))}
                                    selected={JSON.parse(JSON.stringify(selected_codes))}
                                    showAllOption={false}
                                />
                                <div>Or type in challenge codes(comma separated)</div>
                                <textarea
                                    value={codes}
                                    onChange={event => {
                                        const value = event.target.value;
                                        setCodes(value);
                                        setSelectedCodes(value.split(',').map(code => code.trim()).filter(code => code !== '').filter(code => challenge_codes.map(c => c.code).includes(code)));
                                    }}/>
                                <DatePicker
                                    date={end_date}
                                    onChange={setEndDate}/>
                            </div>
                            <button
                                className='button no-wrap'
                                onClick={event => {
                                    event.preventDefault();
                                    if (validate()) {
                                        dispatch(CreateDistrictChallengeLocksAction(district_id, {
                                            challenge_code_ids: challenge_codes.filter(cc => selected_codes.includes(cc.code)).map(cc => cc.id),
                                            end_date
                                        })).then(() => {
                                            setSelectedCodes([]);
                                            setCodes('');
                                            setShow(false);
                                            action();
                                        }).catch(error => {
                                            dispatch(LoadingAction(false));
                                            dispatch(ErrorMessageAction(error));
                                            console.error(error);
                                        });
                                    }
                                }}
                                disabled={selected_codes.length <= 0 || !end_date}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div>Challenge Locks found: {challenge_locks.length}</div>
                <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr className="text-center">
                            <th scope="col">No</th>
                            <th scope="col">Challenge</th>
                            <th scope="col">Starts on</th>
                            <th scope="col">Ends on</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {challenge_locks.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.latest_challenge_version_name}</td>
                                <td>
                                    <div
                                        className='no-wrap'>{new Date(item.created_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</div>
                                </td>
                                <td>
                                    <div
                                        className='no-wrap'>{new Date(item.end_date * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</div>
                                </td>
                                <td>
                                    <button
                                        className='button red'
                                        onClick={() => {
                                            dispatch(DeleteChallengeLockAction(item.id)).then(action);
                                        }}
                                    >
                                        <i className='fas fa-trash'/>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
