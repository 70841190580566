import {
    LOADED_QUESTION_PRACTICE_SKILL_GROUPS,
    LOADED_QUESTION_PRACTICE_SKILL_GROUPS_CURRENT,
    LOADED_QUESTION_PRACTICE_SKILL_GROUPS_LIMIT,
    LOADED_QUESTION_PRACTICE_SKILL_GROUPS_PAGES,
    LOADED_QUESTION_PRACTICE_SKILL_GROUPS_TOTAL,
    SET_QUESTION_PRACTICE_SKILL_UPDATED
} from "../actions/question_actions";

const searchParams = new URLSearchParams(location.search);

const question_practice_skills_updated = (payload = [], action) => {
    switch (action.type) {
        case SET_QUESTION_PRACTICE_SKILL_UPDATED:
            return action.payload;
        default:
            return payload;
    }
};

const question_practice_skill_groups = (payload = [], action) => {
    switch (action.type) {
        case LOADED_QUESTION_PRACTICE_SKILL_GROUPS:
            return action.payload;
        default:
            return payload;
    }
};
const question_practice_skill_groups_current = (payload = searchParams.has('current') ? parseInt(searchParams.get('current')) : 1, action) => {
    switch (action.type) {
        case LOADED_QUESTION_PRACTICE_SKILL_GROUPS_CURRENT:
            return action.payload;
        default:
            return payload;
    }
};
const question_practice_skill_groups_total = (payload = 0, action) => {
    switch (action.type) {
        case LOADED_QUESTION_PRACTICE_SKILL_GROUPS_TOTAL:
            return action.payload;
        default:
            return payload;
    }
};
const question_practice_skill_groups_pages = (payload = 0, action) => {
    switch (action.type) {
        case LOADED_QUESTION_PRACTICE_SKILL_GROUPS_PAGES:
            return action.payload;
        default:
            return payload;
    }
};
const question_practice_skill_groups_limit = (payload = searchParams.has('limit') ? parseInt(searchParams.get('limit')) : 25, action) => {
    switch (action.type) {
        case LOADED_QUESTION_PRACTICE_SKILL_GROUPS_LIMIT:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    question_practice_skills_updated,
    question_practice_skill_groups,
    question_practice_skill_groups_current,
    question_practice_skill_groups_total,
    question_practice_skill_groups_pages,
    question_practice_skill_groups_limit
}
