import React from 'react';
import {HTMLContent, contentUtils} from "mindsets-js-sdk";

import BaseAnswer from '../BaseAnswer';
import FractionPart from './FractionPart';


export default class Fraction extends BaseAnswer {
    defaultAnswer() {
        const items = _.get(this.props.component_data, 'data.items');
        const fractions = _.filter(items, {type: 'fraction'});
        const answer_selections = fractions.map((fractionData) => {
            return _.mapValues(fractionData.inputs, () => '');
        });
        return {
            text: '',
            answer_selections
        }
    }

    isValid() {
        return true;
    }

    onChange(fractionKey) {
        return (fieldKey) => e => {
            e.preventDefault();
        }
    }

    fractionPart(fractionData, fractionKey, inputKey, answer_selections) {
        const text = _.get(fractionData, ['texts', inputKey, 'content', 'text']);
        const answer = _.get(answer_selections, [fractionKey, inputKey], '');
        return text ?? answer;
    }

    getAnswerText(answer_selections) {
        const isNotEmpty = !!_.find(answer_selections, (fraction) => {
            const front = _.get(fraction, 'front');
            const top = _.get(fraction, 'top');
            const bottom = _.get(fraction, 'bottom');
            return !!front || !!top || !!bottom;
        });
        if (!isNotEmpty) {
            return '';
        }

        const items = _.get(this.props.component_data, 'data.items', []);
        let fractionKey = -1;
        const textArray = items.map((item) => {
            if (item.type === 'fraction') {
                fractionKey++;
                // e.g. 5 frac{1}{2}
                const front = this.fractionPart(item, fractionKey, 'front', answer_selections);
                const top = this.fractionPart(item, fractionKey, 'top', answer_selections);
                const bottom = this.fractionPart(item, fractionKey, 'bottom', answer_selections);
                return `${front} frac{${top}}{${bottom}}`;
            } else {
                return _.get(item, 'content.text');
            }
        })
        return textArray.join('');
    }

    renderItem(itemData, fractionKey) {
        switch (itemData.type) {
            case 'hidden':
                return null;
            case 'static':
                const content = _.get(itemData, 'content.html');
                return (
                    <div className="cc-numberonly-item-static" style={itemData.style}>
                        <HTMLContent html_str={contentUtils.processStringContent(content)}/>
                    </div>
                );
            case 'fraction':
                return (
                    <FractionPart
                        component_data={this.props.component_data}
                        is_preview={this.props.is_preview}
                        answer={{}}
                        existing_answer={this.props.existing_answer}
                        onChange={this.onChange(fractionKey)}
                        itemData={itemData}
                        fractionKey={fractionKey}
                    />
                );
            default:
                return null;
        }
    }

    renderAllItems() {
        const items = _.get(this.props.component_data, 'data.items', []);
        let fractionKey = -1;
        return items.map((itemData, itemKey) => {
            if (itemData.type === 'fraction') {
                fractionKey++;
            }
            return (
                <div key={itemKey}>
                    {this.renderItem(itemData, fractionKey)}
                </div>
            );
        });
    }

    render() {
        return (
            <div className="challenge-component-fraction">
                <div className="cc-fraction-description">
                    {`Enter a number in each box below.`}
                </div>

                <div className="d-flex flex-wrap align-items-center">
                    {this.renderAllItems()}
                </div>
            </div>
        )
    }
}
