import React from 'react';
import PropTypes from 'prop-types';
import {HTMLContent, contentUtils} from "mindsets-js-sdk";

const Spreadsheet = function ({question, response}) {
    const spreadsheet = question.spreadsheet;

    function renderTableHeader() {
        if (spreadsheet.headers.length > 0) {
            return (
                <thead>
                {spreadsheet.headers.map((row, i) => {
                    if (!Array.isArray(row)) {
                        row = Object.values(row);
                    }

                    return (
                        <tr key={i}>
                            {row.map((cell, n) => {
                                const style = (typeof cell === 'object') ? cell.style : undefined
                                const text = (typeof cell === 'object') ? cell.title : cell
                                return (
                                    <th key={n} style={style}>
                                        {text ? <HTMLContent
                                            html_str={contentUtils.processStringContent(text, {convert_spelling: false})}/> : null}
                                    </th>
                                )
                            })}
                        </tr>
                    )
                })}
                </thead>
            )
        }
    }

    function renderImage(cell) {
        if (!!cell && cell.image) {
            return (
                <div>
                    <img src={cell.image} alt=''/>
                </div>
            )
        }
    }

    function renderText(cell) {
        if (!cell) {
            return null;
        }
        if (typeof cell === 'string') {
            return contentUtils.processStringContent(cell, {convert_spelling: false});
        }
        if (cell.editable !== undefined && !cell.editable) {
            return (
                <HTMLContent html_str={contentUtils.processStringContent(cell.title, {convert_spelling: false})}/>
            );
        }

        return contentUtils.processStringContent(typeof cell === 'string' ? cell : cell.title, {convert_spelling: false});
    }

    function renderTableRows() {
        let user_data;
        if (!Array.isArray(response)) {
            user_data = JSON.parse(response);
        } else {
            user_data = JSON.parse(JSON.stringify(response));
        }
        const spreadsheet_body_data = _.merge(
            [],
            spreadsheet.data,
            user_data
        )

        return spreadsheet_body_data.map((row, row_key) => {
            if (!Array.isArray(row)) {
                row = Object.values(row);
            }

            return (
                <tr key={row_key}>
                    {row.map((cell, col_key) => {
                        return (
                            <td key={col_key}>
                                <div>
                                    {renderImage(cell)}
                                    {renderText(cell)}
                                </div>
                            </td>
                        )
                    })}
                </tr>
            );
        })
    }

    if (spreadsheet) {
        return (
            <table>
                {renderTableHeader()}

                <tbody>
                {renderTableRows()}
                </tbody>
            </table>
        );
    }

    return null;
}

Spreadsheet.propTypes = {
    question: PropTypes.shape({
        spreadsheet: PropTypes.shape({
            headers: PropTypes.array.isRequired,
            data: PropTypes.array.isRequired,
        })
    }).isRequired,
    response: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ])
};

export default Spreadsheet;
